import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button,
  TextField,
  Typography,
  useTheme,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Grow from "@material-ui/core/Grow/Grow";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar, useSnackbar } from "notistack";
import { votingService } from "../../services/votingService";
import { NpuError } from "../../models/error.model";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { VotingType } from "../../models/voting.model";

interface VotingOption {
  id: number;
  name: string;
}


const values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: "blur(7px)",
    backgroundColor: `${theme.palette.grey[100]}30`,
  },
  dates: {
    marginTop: '10px',
  },
  value: {
    backgroundColor: `${theme.palette.grey[50]}50`,
    width: '50px',
    height: '100%',
    cursor: 'pointer',
    borderRadius: '50%',
    transition: 'all 0.1s'
  },
  valueSelected: {
    backgroundColor: `${theme.palette.primary.light}`,
    width: '50px',
    height: '100%',
    borderRadius: '50%',
    transform: 'scale(1.1)',
    transition: 'all 0.1s',
    border: 'solid 2px #fff'
  }
}));

const EditedVoting = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => (
    <IconButton onClick={() => closeSnackbar()} >
      <CloseIcon fontSize="small" />
    </IconButton>
  );


  const [open, setOpen] = React.useState(true);


  const [voting, setVoting] = useState({
    typeId: props.voting.votingType.id,
    subject: props.voting.subject,
    body: props.voting.body,
    threshold: props.voting.threshold,
    options: props.voting.options.sort((a: any, b: any) => a.id - b.id),
    bulletinHashId: props.bulletinId,
    hashId: props.voting.hashId,
    note: props.voting.note,
    urls: props.voting.urls,
  });


  const handleClickOpen = () => {
    setOpen(true);
    // console.log("open : ", open);
  };

  const handleClose = () => {
    setOpen(false);

    props.getUpdateVoting()
  };

  const handleChange = (name: string, value: string) => {
    setVoting((oldVals: any) => ({ ...oldVals, [name]: value }))
  }

  const handleChangeVotingType = (name: string, value: number) => {
    setVoting((oldVals: any) => ({ ...oldVals, [name]: value }))
  }

  const [options, setOptions] = useState(voting.options)

  const handleAddOption = (elem: any) => {

    setOptions((oldOpt: any) => ([...oldOpt, elem]))
  }

  const handleChangeOptions = (i: any, value: any) => {
    let a = [...options][i]

    let editedField = {
      ...a,
      name: value
    }
    setOptions((oldOpts: any) => [...oldOpts.filter((item: any) => item.id !== a.id), editedField]
      .filter((item: any) => Boolean(item.name))
      .sort((a: any, b: any) => a.id - b.id)
    )

  }


  const [urls, setUrls] = useState(props.voting.urls)

  const handleAddUrls = (elem: any) => setUrls((oldOpt: any) => ([...oldOpt, elem]))


  const handleChangeNameForUrls = (i: any, value: any) => {

    let a = [...urls][i]

    let editedField = {
      ...a,
      name: value,
    }
    setUrls((oldUrls: any) => ([...oldUrls.filter((item: any) => item.id !== a.id), editedField]
      .filter((item: any) => Boolean(item.name))
      .sort((a: any, b: any) => a.id - b.id)))
  }

  const handleChangeUrlForUrls = (i: any, value: any) => {

    let a = [...urls][i]

    let editedField = {
      ...a,
      url: value
    }
    setUrls((oldUrls: any) => ([...oldUrls.filter((item: any) => item.id !== a.id), editedField]
      .filter((item: any) => Boolean(item.name))
      .sort((a: any, b: any) => a.id - b.id)))
  }

  const updateVoting = () => {
    const cleanOptions = (options: Array<VotingOption>) => {
      return options.filter((o: VotingOption) => o.name.length != 0 || o.name != undefined || Boolean(o.name));
    }

    const reindexOptions = (options: Array<VotingOption>) => {
      const sortedOptions = options.sort((a, b) => a.id - b.id);
      const reindexedOptions = sortedOptions.map((o: VotingOption, i: number) => {
        o.id = i;
        return o;
      })
      return reindexedOptions;
    }

    const reqData = {
      typeId: voting.typeId,
      bulletinHashId: voting.bulletinHashId,
      votingHashId: voting.hashId,
      subject: voting.subject.trim(),
      body: voting.body.trim(),
      threshold: voting.threshold,
      note: voting.note.trim(),
      urls: urls,
      options: reindexOptions(cleanOptions(options))
    };

    votingService.editVoting(reqData)
      .then(() => handleClose())
      .then(() => props.getUpdateVoting())
      .catch((e: NpuError) =>
        enqueueSnackbar(e.message, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        })
      );
  };


  const handleDelete = () => {
    const req = {
      bulletinHashId: voting.bulletinHashId,
      votingHashId: voting.hashId,
    }
    votingService.deleteVoting(req)
      .then(() => handleClose())
      .then(() => props.getUpdateVoting())
      .catch((e: NpuError) =>
        enqueueSnackbar(e.message, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        })
      );
  }

  useEffect(() => {
    setVoting((oldState: any) => ({ ...oldState, threshold: props.voting.threshold }))
  }, [props.voting.threshold])

  const changeThreshold = (e: any) => {
    const thresholdValue = e.target.value

    if (thresholdValue <= 1) {

      setVoting((oldState: any) => ({ ...oldState, threshold: 1 }))

    } else {
      setVoting((oldState: any) => ({ ...oldState, threshold: Number(thresholdValue) }))

    }

  }



  return (
    <>


      <Grid container item xs={12} justify="center">
        <Dialog
          maxWidth="md"
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
            timeout: 400,
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: theme.palette.grey[900] }}
          >
            <Grid container item xs={12} justify='space-between'>
              <Grid item>
                {props.voting.votingType && props.voting.votingType.id === VotingType.Voting.valueOf() &&
                  (`Редагування блоку Голосування №${props.votingNumber}`)
                }
                {props.voting.votingType && props.voting.votingType.id === VotingType.Poll.valueOf() &&
                  (`Редагування блоку Опитування №${props.votingNumber}`)
                }
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handleDelete}
                  color='secondary'
                  startIcon={<DeleteForeverIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  <Typography>Видалити</Typography>
                </Button>
              </Grid>


            </Grid>
          </DialogTitle>
          <DialogContent
            dividers={true}
            style={{ backgroundColor: theme.palette.grey[900] }}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {/* <DialogContentText>{t("betaTest.titleFeedback")}</DialogContentText>   */}

            <Grid container item xs={12} >

              <FormControl margin="dense" variant="outlined" fullWidth>
                <Select
                  labelId="voting-type-select-label"
                  id="voting-type-select"
                  variant="outlined"
                  name="typeId"
                  value={voting.typeId}
                  onChange={(e: any) =>
                    handleChangeVotingType(e.target.name, e.target.value)
                  }
                >
                  <MenuItem value={0}>Голосування</MenuItem>
                  <MenuItem value={1}>Опитування</MenuItem>
                </Select>
              </FormControl>

              <TextField
                label='Заголовок'
                // label={t("licences.licenceN")}
                name="subject"
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="10"
                value={voting.subject}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }

              // inputProps={{ disabled: true }}
              />
              <TextField
                label='Основний текст'
                // label={t("licences.licenceN")}
                name="body"
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="10"
                value={voting.body}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }

              // inputProps={{ disabled: true }}
              />
              <TextField
                label='Нотатки'
                // label={t("licences.licenceN")}
                name="note"
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="10"
                value={voting.note}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }

              // inputProps={{ disabled: true }}
              />

              <Grid container item xs={12} style={{ marginTop: 40 }} >
                <Grid container item xs={12}>
                  <Typography>
                    Мінімальна кількість варіантів вибору:
                  </Typography>
                </Grid>


                <Grid container item xs={12} style={{ height: 50, marginTop: 10 }} justify='space-between' direction='row'>
                  <TextField
                    onChange={changeThreshold}
                    variant='outlined'
                    autoFocus
                    margin="dense"
                    // label="thresholdModal"
                    type='number'
                    fullWidth
                    value={voting.threshold}
                  />

                </Grid>

              </Grid>

              <Grid container item xs={12} style={{ marginTop: 40 }}>

                <Typography>
                  Варіанти:
                </Typography>



                <Grid container item xs={12}>



                  {options.filter((item: any) => Boolean(item)).map((item: any, index: any) => (
                    <Grid key={item.id} container item xs={12} justify='space-between'>
                      <Grid item xs={12}>

                        <TextField
                          // label=
                          // label={t("licences.licenceN")}
                          name={item.hashId}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          multiline
                          rows={1}
                          rowsMax="10"
                          value={item.name}
                          onChange={(e: any) =>
                            handleChangeOptions(index, e.target.value)
                          }

                        // inputProps={{ disabled: true }}
                        />
                      </Grid>

                    </Grid>
                  ))}

                </Grid>


                <Grid container item xs={12} justify='flex-end'>
                  <Grow in>
                    <IconButton
                      onClick={(e: any) => {
                        handleAddOption({
                          name: '',
                          id: options.length ? options[options.length - 1].id + 1 : 100000
                        })
                      }}
                      color="primary"
                      style={{ width: 50, height: 50 }}
                    >
                      <AddCircleIcon fontSize="large" style={{ width: 40, height: 40 }} />
                    </IconButton>
                  </Grow>
                </Grid>

              </Grid>

              <Grid container item xs={12} style={{ marginTop: 40 }} justify='space-between'>
                <Grid item xs={6}>
                  <Typography>
                    Назва посилання:
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography>
                    URL:
                  </Typography>
                </Grid>

                <Grid container item xs={12}>



                  {urls.map((item: any, index: any) => (
                    <Grid key={item.id} container item xs={12} justify='space-between'>
                      <Grid container item xs={12} direction='row' justify='space-between'>
                        <Grid item xs={6}>

                          <TextField
                            // label=
                            // label={t("licences.licenceN")}
                            name='name'
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            multiline
                            rows={1}
                            rowsMax="10"
                            value={item.name}
                            onChange={(e: any) =>
                              handleChangeNameForUrls(index, e.target.value)
                            }

                          // inputProps={{ disabled: true }}
                          />
                        </Grid>
                        <Grid item xs={5}>

                          <TextField
                            // label=
                            // label={t("licences.licenceN")}
                            name='url'
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            multiline
                            rows={1}
                            rowsMax="10"
                            value={item.url}
                            onChange={(e: any) =>
                              handleChangeUrlForUrls(index, e.target.value)
                            }

                          // inputProps={{ disabled: true }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                <Grid container item xs={12} justify='flex-end'>
                  <Grow in>
                    <IconButton
                      onClick={() => handleAddUrls({ name: '', url: '', id: urls.length ? urls[urls.length - 1].id + 1 : 10000000 })}
                      color="primary"
                      style={{ width: 50, height: 50 }}
                    >
                      <AddCircleIcon fontSize="large" style={{ width: 40, height: 40 }} />
                    </IconButton>
                  </Grow>
                </Grid>
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions style={{ backgroundColor: theme.palette.grey[900] }}>
            <Grid container item xs={12} justify="space-between">
              <Grid>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="primary"
                  startIcon={<CancelIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  <Typography>{t("btns.cancel")}</Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  onClick={updateVoting}
                  color="primary"
                  startIcon={<SendIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  {/* <Typography>{ t("btns.send") }</Typography> */}
                  <Typography>Зберегти</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default withSnackbar(EditedVoting);
