import { RcmdRequest } from "./rcmd-request.model";

export enum Role {
  Empty = "empty",
  Unknown = "unknown",
  Admin = "admin",
  User = "user",
  Assistant = "assistant",
}

export enum AuthProvider {
  Linkedin = "linkedin",
  Google = "google",
  GitHub = "github",
}

export enum Lang {
  EN = "en",
  RU = "ru",
  UA = "ua",
}

export enum LangId {
  EN = 0,
  RU = 1,
  UA = 2,
}

export enum ThemeId {
  Auto = 0,
  Dark = 1,
  Light = 2,
}

export type Hash = string;
export type EncryptedString = string;

export interface RoleView {
  id: number;
  name: string;
}

export interface Theme {
  id: number;
  name: string;
}

export interface LangBackEnd {
  id: number;
  code: string;
  name: string;
}
export interface StaticModel {
  id: number;
  name: string;
}
