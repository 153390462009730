import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button,
  TextField,
  withStyles,
  Typography,
  makeStyles,
  Tooltip,
  Theme,
  useTheme,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Grow from "@material-ui/core/Grow/Grow";
import { NpuError } from "../../models/error.model";

import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar, useSnackbar } from "notistack";
import { bulletinService } from "../../services/bulletinService";
import { transform } from "lodash";
import { votingService } from "../../services/votingService";
import { Voting, VotingType } from "../../models/voting.model";


const values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

interface VotingOption {
  id: number;
  name: string;
}

const LightTextInDisabledTextField = withStyles({
  root: {
    // marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#fefefe", // (default alpha is 0.38)
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      color: "#fefefe90", // (default alpha is 0.38)
    },
  },
})(TextField);



const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[4],
    // borderRadius: 20,
    fontSize: 15,

  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: "blur(7px)",
    backgroundColor: `${theme.palette.grey[100]}30`,
  },
  dates: {
    marginTop: '10px',
  },
  value: {
    backgroundColor: `${theme.palette.grey[50]}50`,
    width: '50px',
    height: '100%',
    cursor: 'pointer',
    borderRadius: '50%',
    transition: 'all 0.1s',
    "&:hover": {
      backgroundColor: `${theme.palette.primary.light}90`,
      transition: 'all 0.1s',
    },
  },
  valueSelected: {
    backgroundColor: `${theme.palette.primary.light}`,
    width: '50px',
    height: '100%',
    borderRadius: '50%',
    transform: 'scale(1.1)',
    transition: 'all 0.1s',
    border: 'solid 2px #fff',
    cursor: 'default',
  }
}));

const AddVoting = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };


  const [openModal, setOpenModal] = React.useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [isEdit, setIsEdit] = React.useState(false);

  const handleEdit = () => {
    setIsEdit(!isEdit)
  }
  const [open, setOpen] = React.useState(false);
  const initialVoting = {
    subject: '',
    body: '',
    note: '',
    threshold: 1,
    bulletinHashId: '',
    options: [{ name: '', id: 0 }],
    urls: [{ name: '', url: '', id: 0 }],
  }

  const [voting, setVoting] = useState(initialVoting)

  const [votingTypeId, setVotingTypeId] = useState<number>(0)

  const handleClickOpen = () => {
    setOpen(true);
    // console.log("open : ", open);
  };

  const handleClose = () => {
    setOpen(false);

  };





  const handleChange = (name: string, value: string) => {
    if (name === 'subject') {
      setVoting((oldState: any) => ({ ...oldState, subject: value }))
    } else if (name === 'note') {
      setVoting((oldState: any) => ({ ...oldState, note: value }))
    } else if (name === 'body') {
      setVoting((oldState: any) => ({ ...oldState, body: value }))
    }
  }

  const [additionalThresholdValue, setAdditionalThresholdValue] = useState(0)


  const handleChangeTrashHold = (val: number) => {

    setVoting((oldState: any) => ({ ...oldState, threshold: val }))

  }

  const initialOptions = [{
    name: '',
    id: 0
  }]
  const [options, setOptions] = useState(initialOptions)

  const handleAddOption = (elem: any) => setOptions((oldOpt: any) => ([...oldOpt, elem]))


  const initialUrls = [{
    name: '',
    url: '',
    id: 0
  }]




  const handleChangeOptions = (i: any, value: any) => {
    let a = [...options][i]

    let editedField = {
      ...a,
      name: value
    }
    setOptions((oldOpts: any) => [...oldOpts.filter((item: any) => item.id !== a.id), editedField]
      .filter((item: any) => Boolean(item.name)
        //   ? item.name : {
        //   name: '',
        //   url: '',
        //   id: ''
        // }
    )
      .sort((a: any, b: any) => a.id - b.id)
    )

  }


  const [urls, setUrls] = useState(initialUrls)


  const handleAddUrls = (elem: any) => setUrls((oldOpt: any) => ([...oldOpt, elem]))



  const handleChangeNameForUrls = (i: any, value: any) => {

    let a = [...urls][i]

    let editedField = {
      ...a,
      name: value,
    }
    setUrls((oldUrls: any) => ([...oldUrls.filter((item: any) => item.id !== a.id), editedField]
      .filter((item: any) => Boolean(item.name))
      .sort((a: any, b: any) => a.id - b.id)))
  }

  const handleChangeUrlForUrls = (i: any, value: any) => {

    let a = [...urls][i]

    let editedField = {
      ...a,
      url: value
    }
    setUrls((oldUrls: any) => ([...oldUrls.filter((item: any) => item.id !== a.id), editedField]
      .filter((item: any) => Boolean(item.name))
      .sort((a: any, b: any) => a.id - b.id)))
  }


  const sendNewVoting = () => {
    const cleanOptions = (options: Array<VotingOption>) => {
      return options.filter((o: VotingOption) => o.name.length != 0 || o.name != undefined || Boolean(o.name));
    }

    const reindexOptions = (options: Array<VotingOption>) => {
      const sortedOptions = options.sort((a, b) => a.id - b.id);
      const reindexedOptions = sortedOptions.map((o: VotingOption, i: number) => {
        o.id = i;
        return o;
      })
      return reindexedOptions;
    }

    const reqData = {
      votingTypeId: votingTypeId,
      subject: voting.subject.trim(),
      body: voting.body.trim(),
      note: voting.note.trim(),
      threshold: voting.threshold,
      bulletinHashId: props.bulletin.hashId,
      urls: urls.filter((item: any) => Boolean(item.url)),
      options: reindexOptions(cleanOptions(options)),
    };

    votingService.addVotingToBulletin(reqData)
      .then(() => handleClose())
      .then(() => props.getUpdateVoting())
      .then(() => {
        setVoting(initialVoting);
        setOptions(initialOptions);
        setUrls(initialUrls);
      })
      .catch((e: NpuError) =>
        enqueueSnackbar(e.message, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        })
      );

  };


  const changeThreshold = (e: any) => {
    const thresholdValue = e.target.value

    if (thresholdValue <= 1) {

      setVoting((oldState: any) => ({ ...oldState, threshold: 1 }))

    } else {
      setVoting((oldState: any) => ({ ...oldState, threshold: Number(thresholdValue) }))

    }

  }

  const handleVotingType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setVotingTypeId(event.target.value as number);
  };

  return (
    <>
      <Grow in>
        <Grid>
          <LightTooltip
            placement='bottom'
            enterDelay={200}
            leaveDelay={200}
            title="Додати голосування/опитування">
            <IconButton
              onClick={handleClickOpen}
              color="primary"
              style={{ width: 90, height: 90 }}
            >
              <AddCircleIcon fontSize="large" style={{ width: 80, height: 80 }} />
            </IconButton>
          </LightTooltip>
        </Grid>
      </Grow>

      <Grid container item xs={12} justify="center">
        <Dialog
          maxWidth="lg"
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
            timeout: 400,
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: theme.palette.grey[900] }}
          >
            {votingTypeId === VotingType.Voting.valueOf() &&
              <Typography variant="h6" display="inline"> {"Створити Голосування"} </Typography>
            }
            {votingTypeId === VotingType.Poll.valueOf() &&
              <Grid container item xs={12} justify="space-between">
                <Typography variant="h6" display="inline"> {"Створити Опитування"} </Typography>
                <Chip variant="outlined" label="не анонімне голосування" color="secondary" />
              </Grid>
            }
          </DialogTitle>
          <DialogContent
            dividers={true}
            style={{ backgroundColor: theme.palette.grey[900] }}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <Grid container item xs={12} >

              <FormControl margin="dense" variant="outlined" fullWidth>
                <InputLabel id="voting-type-select-label"> Тип </InputLabel>
                <Select
                  labelId="voting-type-select-label"
                  id="voting-type-select"
                  variant="outlined"
                  value={votingTypeId}
                  onChange={handleVotingType}
                >
                  <MenuItem value={0}>Голосування</MenuItem>
                  <MenuItem value={1}>Опитування</MenuItem>
                </Select>
              </FormControl>

              <TextField
                label='Заголовок'
                // label={t("licences.licenceN")}
                name="subject"
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="10"
                value={voting.subject}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }

              // inputProps={{ disabled: true }}
              />
              <TextField
                label='Основний текст'
                // label={t("licences.licenceN")}
                name="body"
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="10"
                value={voting.body}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }

              // inputProps={{ disabled: true }}
              />
              <TextField
                label='Нотатки'
                // label={t("licences.licenceN")}
                name="note"
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="10"
                value={voting.note}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }

              // inputProps={{ disabled: true }}
              />

              <Grid container item xs={12} style={{ marginTop: 40 }} >
                <Grid container item xs={12}>
                  <Typography>
                    Мінімальна кількість варіантів вибору:
                  </Typography>
                </Grid>
                <Grid container item xs={12}>

                  <TextField
                    onChange={changeThreshold}
                    variant='outlined'
                    autoFocus
                    margin="dense"
                    // label="thresholdModal"
                    type='number'
                    fullWidth
                    value={voting.threshold}
                  />

                </Grid>


                {/* <Grid container item xs={ 12 }
                  style={ { marginTop: 10 } }
                  justify='space-between' direction='row'>


                  { values.map((val) => (
                    <Grid
                      key={ val }
                      item
                      container
                      className={ voting.threshold === val ? classes.valueSelected : classes.value }
                      onClick={ () => handleChangeTrashHold(val) }
                      justify='center'
                      alignItems='center'
                      alignContent='center'
                      direction='column'
                      style={ { height: 50, marginTop: 10 } }
                    >


                      { val > 10 ?


                        <div  >
                          <Typography variant='h6' onClick={ handleClickOpenModal }>
                            +
                          </Typography>
                          <Dialog open={ openModal } onClose={ handleCloseModal } aria-labelledby="thresholdModal">

                            <DialogContent>
                              <DialogContentText>
                                Введіть потрібну кількість варіанті для голосування
                              </DialogContentText>
                              <TextField
                                onChange={ changeThreshold }
                                autoFocus
                                margin="dense"
                                id="name"
                                label="thresholdModal"
                                type='number'
                                fullWidth
                                defaultValue={ 11 }
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={ handleCloseModal } color="primary">
                                Відмінити
                              </Button>
                              <Button onClick={ handleChangeAdditionalThreshold } color="primary">
                                Прийняти
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </div>

                        :
                        <Typography variant='h6'>
                          { val }
                        </Typography> }

                    </Grid>
                  )) }
                </Grid> */}

              </Grid>




              <Grid container item xs={12} style={{ marginTop: 40 }}>

                <Typography>
                  Варіанти:
                </Typography>



                <Grid container item xs={12}>

                  {options.map((item: any, index: any) => (
                    <Grid key={item.id} container item xs={12} justify='space-between'>
                      <Grid item xs={12}>

                        <TextField
                          // label=
                          // label={t("licences.licenceN")}
                          name={item.hashId}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          multiline
                          rows={1}
                          rowsMax="10"
                          value={item.name}
                          onChange={(e: any) =>
                            handleChangeOptions(index, e.target.value)
                          }

                        // inputProps={{ disabled: true }}
                        />
                      </Grid>

                    </Grid>
                  ))}

                </Grid>


                <Grid container item xs={12} justify='flex-end'>
                  <Grow in>
                    <IconButton
                      onClick={(e: any) => {
                        handleAddOption({
                          name: '',
                          id: options.length ? options[options.length - 1].id + 1 : 100000
                        })
                      }}
                      color="primary"
                      style={{ width: 50, height: 50 }}
                    >
                      <AddCircleIcon fontSize="large" style={{ width: 40, height: 40 }} />
                    </IconButton>
                  </Grow>
                </Grid>

              </Grid>

              <Grid container item xs={12} style={{ marginTop: 40 }}>

                <Typography>
                  Посилання:
                </Typography>

                <Grid container item xs={12}>

                  {urls.map((item: any, index: any) => (
                    <Grid key={item.id} container item xs={12} justify='space-between'>
                      <Grid container item xs={12} direction='row' justify='space-between'>
                        <Grid item xs={6}>

                          <TextField
                            // label=
                            // label={t("licences.licenceN")}
                            name='name'
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            multiline
                            rows={1}
                            rowsMax="10"
                            value={item.name}
                            onChange={(e: any) =>
                              handleChangeNameForUrls(index, e.target.value)
                            }

                          // inputProps={{ disabled: true }}
                          />
                        </Grid>
                        <Grid item xs={5}>

                          <TextField
                            // label=
                            // label={t("licences.licenceN")}
                            name='url'
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            multiline
                            rows={1}
                            rowsMax="10"
                            value={item.url}
                            onChange={(e: any) =>
                              handleChangeUrlForUrls(index, e.target.value)
                            }

                          // inputProps={{ disabled: true }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}

                </Grid>

                <Grid container item xs={12} justify='flex-end'>
                  <Grow in>
                    <IconButton
                      onClick={() => handleAddUrls({ name: '', url: '', id: urls.length ? urls[urls.length - 1].id + 1 : 10000000 })}
                      color="primary"
                      style={{ width: 50, height: 50 }}
                    >
                      <AddCircleIcon fontSize="large" style={{ width: 40, height: 40 }} />
                    </IconButton>
                  </Grow>
                </Grid>

              </Grid>


            </Grid>
          </DialogContent>
          <DialogActions style={{ backgroundColor: theme.palette.grey[900] }}>
            <Grid container item xs={12} justify="space-between">
              <Grid>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="primary"
                  startIcon={<CancelIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  <Typography>{t("btns.cancel")}</Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  onClick={sendNewVoting}
                  color="primary"
                  startIcon={<SendIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  <Typography>Зберегти</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default withSnackbar(AddVoting);
