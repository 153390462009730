import { RcmdRequest } from "./rcmd-request.model";
import { User } from "./user.model";
import { BulletinQuorumTypeView, RegisteredNotariesSourceTypeView, Voting } from "./voting.model";

export interface BulletinStatusView {
  id: number;
  name: string;
}

export class NewBulletinRequest implements RcmdRequest {
  headerText: string;
  bodyText: string;
  protocolNumber: number;
  protocolDate: number;
  startDate: number;
  endDate: number;
  note?: string;

  constructor(
    headerText: string,
    bodyText: string,
    protocolNumber: number,
    protocolDate: number,
    startDate: number,
    endDate: number,
    note?: string
  ) {
    this.headerText = headerText;
    this.bodyText = bodyText;
    this.protocolNumber = protocolNumber;
    this.protocolDate = protocolDate;
    this.startDate = startDate;
    this.endDate = endDate;
    this.note = note;
  }
}

export class UpdateBulletinInfoRequest implements RcmdRequest {
  headerText?: string;
  bodyText?: string;
  hashId: string;
  protocolNumber: number;
  protocolDate: number;
  startDate: number;
  endDate: number;
  note?: string;

  constructor(
    headerText: string,
    bodyText: string,
    hashId: string,
    protocolNumber: number,
    protocolDate: number,
    startDate: number,
    endDate: number,
    note?: string
  ) {
    this.headerText = headerText;
    this.bodyText = bodyText;
    this.hashId = hashId;
    this.protocolNumber = protocolNumber;
    this.protocolDate = protocolDate;
    this.startDate = startDate;
    this.endDate = endDate;
    this.note = note;
  }
}

export class UpdateBulletinProtocolNumberRequest implements RcmdRequest {
  hashId: string;
  protocolNumber: number;

  constructor(hashId: string, protocolNumber: number) {
    this.hashId = hashId;
    this.protocolNumber = protocolNumber;
  }
}

export class UpdateBulletinProtocolDateRequest implements RcmdRequest {
  hashId: string;
  protocolDate: number;

  constructor(hashId: string, protocolDate: number) {
    this.hashId = hashId;
    this.protocolDate = protocolDate;
  }
}

export class UpdateBulletinStartDateRequest implements RcmdRequest {
  hashId: string;
  startDate: number;

  constructor(hashId: string, startDate: number) {
    this.hashId = hashId;
    this.startDate = startDate;
  }
}

export class UpdateBulletinEndDateRequest implements RcmdRequest {
  hashId: string;
  endDate: number;

  constructor(hashId: string, endDate: number) {
    this.hashId = hashId;
    this.endDate = endDate;
  }
}

export class UpdateBulletinNoteRequest implements RcmdRequest {
  hashId: string;
  note: string;

  constructor(hashId: string, note: string) {
    this.hashId = hashId;
    this.note = note;
  }
}

export class CancelBulletinRequest implements RcmdRequest {
  hashId: string;
  cancelReason: string;

  constructor(hashId: string, cancelReason: string) {
    this.hashId = hashId;
    this.cancelReason = cancelReason;
  }
}

export class BulletinFilter implements RcmdRequest {
  pageNumber?: number;
  pageSize?: number;
  statusIds?: Array<number>;
  searchTerm?: string;

  constructor(
    pageNumber?: number,
    pageSize?: number,
    statusIds?: Array<number>,
    searchTerm?: string
  ) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.statusIds = statusIds;
    this.searchTerm = searchTerm;
  }
}

export interface BulletinStats { 
  registeredUsers?: number;
  votedUsers?: number;
  registeredNotaries?: number;
  registeredNotariesSourceType?: RegisteredNotariesSourceTypeView;
  registeredNotariesSource?: string;
  quorum?: number;
  quorumType?: BulletinQuorumTypeView;
}

export interface Bulletin {
  id?: string;
  hashId?: string;
  isVotedByMe?: boolean;
  createdBy?: User;
  status: BulletinStatusView;
  protocolNumber?: number;
  protocolDate?: number;
  startDate?: number;
  endDate?: number;
  note?: string;
  headerText: string;
  bodyText: string;
  votingItems: Array<Voting>;
  createdAt?: number;
  modifiedAt?: number;
  stats?: BulletinStats; 
  bulletinType?: BulletinTypeView;
}

export class SetRegisteredNotariesRequest implements RcmdRequest {
  hashId: string;
  registeredNotaries: number;

  constructor(
    hashId: string,
    registeredNotaries: number
  ) {
    this.hashId = hashId;
    this.registeredNotaries = registeredNotaries;
  }

}

export enum BulletinStatus {
  Draft = 0,
  Published = 1,
  Pending = 2,
  Started = 3,
  Ended = 4,
  Counting = 5,
  Counted = 6,
  Completed = 7,
  Cancelled = 8,
  Archived = 9,
  CancelledArchived = 10,
}

export enum BulletinType {
  Voting = 0,
  Poll = 1,
  VotingAndPoll = 2,
}

export interface BulletinTypeView {
  id: number;
  name: string;
}