import React, { useMemo } from "react";
import {
  Card,
  Chip,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
  withStyles,
  useMediaQuery
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Grow from "@material-ui/core/Grow/Grow";
import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";
import { withSnackbar } from "notistack";
import InputAdornment from '@material-ui/core/InputAdornment';
import history from "../../utils/history";
import { ARCHIVE_PATH, } from "../Routes/var_PATHS";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.background.paper,
    // height: 300,
    width: "100%",
    // margin: "30px auto 0px",
    margin: "30px auto 0px",
    cursor: "pointer",
  },

  btnStylesWithOutline: {
    minHeight: 48,
    border: `2px solid ${ theme.palette.primary.main }`,
    backgroundColor: theme.palette.background.paper,

    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${ theme.palette.primary.main }`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${ theme.palette.primary.main }`,
    },
  },
}));

const LightTextInDisabledTextField = withStyles({
  root: {
    // marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#fefefe", // (default alpha is 0.38)
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      color: "#fefefe90", // (default alpha is 0.38)
    },
  },
})(TextField);

const ArchiveBulletinItem = (props: any) => {
  const { protocolNumber,
    protocolDate,
    startDate,
    endDate,
    note,
    hashId,
    createdBy,
    id,
    createdAt,
    modifiedAt,
    status,
  } = props.bulletin;


  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const isAdmin = myRole === Role.Admin;

  const classes = useStyles();
  const { t } = useTranslation();
  const matches980 = useMediaQuery("(max-width:980px)");
  const matches700 = useMediaQuery("(max-width:700px)");
  const matches530 = useMediaQuery("(max-width:530px)");


  return (
    <Grow in>
      <>
        <Card className={ classes.card } onClick={ () => {
          history.push(`${ ARCHIVE_PATH }/${ hashId }`)
        } }>

          { matches530 && <Grid container item xs={ 12 } style={ { position: 'relative' } } >
            <Chip
              style={ { position: 'absolute', top: 5, right: 5 } }
              color='primary'
              // icon={<AssignmentIndIcon />}
              label={ <span style={ { fontSize: 18 } }>{ status.name }</span> }
            />

          </Grid> }

          <Grid
            container
            item
            xs={ 12 }
            // onClick={() => handleOpenObject(props.user.hashId)}
            style={ { padding: "10px", display: "flex" } }
            justify="space-between"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
          >


            <Grid container item >

              { matches700 ?
                <Grid>
                  <Typography variant='h6' >
                    { `Протокол № ${ protocolNumber }` }

                  </Typography>
                  <Typography variant='h6' >
                    { `від ${ new Date(
                      protocolDate
                    ).toLocaleString('de-DE', {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",

                    }) } року` }

                  </Typography>
                </Grid>
                : <Grid container item
                  xs={ 12 }
                  // direction={ matches980 ? 'column' : "row" }
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  wrap='nowrap'
                >

                  <Grid container direction='row' wrap='nowrap'
                    alignItems="center"
                    justify='flex-start'
                    style={ { width: 'max-content', whiteSpace: 'nowrap' } }
                  >

                    <Typography variant='h6' style={ { marginRight: 15 } }>
                      Протокол { matches980 && `№ ${ protocolNumber }` }
                    </Typography>

                    { !matches980 && <LightTextInDisabledTextField
                      disabled
                      // label={t("referral.input")}
                      // label={t("licences.licenceN")}
                      // label="№"
                      variant="outlined"
                      style={ { width: 160, marginRight: 25 } }
                      value={ protocolNumber }
                      inputProps={ {
                        style: { textAlign: "center", fontSize: 24 },
                        disabled: true,
                        // textAlign: "center",
                      } }

                      InputProps={ {
                        startAdornment: (
                          <InputAdornment position="start">
                            №
                          </InputAdornment>
                        ),
                      } }
                    /> }
                  </Grid>
                  { matches980 ?
                    (<Grid container item direction='row' wrap='nowrap'
                      justify='flex-start'
                    >
                      <Typography variant='h6'>
                        { `від ${ new Date(
                          protocolDate
                        ).toLocaleString('de-DE', {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",

                        }) } року` }
                      </Typography>

                    </Grid>)
                    :
                    (<Grid container item direction='row' wrap='nowrap'
                      alignItems="center"
                    >
                      <Typography variant='h6' style={ { marginRight: 15 } }>
                        від
                      </Typography>
                      <LightTextInDisabledTextField
                        disabled
                        // label={t("referral.input")}
                        // label={t("licences.licenceN")}
                        // label="№"
                        variant="outlined"
                        style={ { width: 200, marginRight: 15 } }
                        value={ new Date(
                          protocolDate
                        ).toLocaleString('de-DE', {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",

                        }) }
                        inputProps={ {
                          style: { textAlign: "center", fontSize: 24 },
                          disabled: true,
                          // textAlign: "center",
                        } }
                      />

                      <Typography variant='h6' style={ {} }>
                        року
                      </Typography>
                    </Grid>)
                  }
                </Grid> }

              { note && <Grid container item xs={ 12 } style={ { marginTop: 10 } }>
                <Typography>
                  { note }
                </Typography>
              </Grid> }

              <Grid container item xs={ 12 } style={ { marginTop: 10 } }>
                <Grid container item xs={ 12 }>

                  <div style={ { width: 120 } }>
                    <Typography variant='h6' >
                      Початок:
                    </Typography>
                  </div>

                  <Typography variant='h6'>

                    { new Date(
                      startDate
                    ).toLocaleTimeString([], {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour12: false,
                      hour: "2-digit",
                      minute: "2-digit",
                    }) }
                  </Typography>
                </Grid>
                <Grid container item xs={ 12 }>
                  <div style={ { width: 120 } }>
                    <Typography variant='h6' >
                      Кінець:
                    </Typography>
                  </div>
                  <Typography variant='h6'>
                    { new Date(
                      endDate
                    ).toLocaleTimeString([], {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour12: false,
                      hour: "2-digit",
                      minute: "2-digit",
                    }) }

                  </Typography>
                </Grid>

              </Grid>


            </Grid>

            { !matches530 && <Grid container item style={ { width: '190px' } }>

              <Grid item style={ { width: '100%', position: 'relative' } }>

                {/* <Grid container item xs={ 12 } > */ }
                <Chip
                  style={ { position: 'absolute', right: '0px' } }
                  color='primary'
                  // icon={<AssignmentIndIcon />}
                  label={ <span style={ { fontSize: 18 } }>{ status.name }</span> }
                />
                {/* </Grid> */ }

              </Grid>

            </Grid> }
          </Grid>
        </Card>

        <Grid container item xs={ 12 } direction='row' justify='space-between'>

          <Grid item>

            { createdAt && (isAdmin || myRole === Role.Assistant) && (
              <Typography align="right" variant="caption">
                { `${ createdBy.licence.fio } | ${ new Date(
                  createdAt
                ).toLocaleTimeString([], {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour12: false,
                  hour: "2-digit",
                  minute: "2-digit",
                }) }` }
              </Typography>
            ) }
          </Grid>
          <Grid item>

            { id && (
              <Typography align="right" variant="caption">
                { id }
              </Typography>
            ) }
          </Grid>

        </Grid>
      </>
    </Grow>
  );
};

export default withSnackbar(ArchiveBulletinItem);


