import React, { useEffect, useMemo, useState } from "react";
import { withSnackbar, useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Grow,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery
} from "@material-ui/core";
// import { useTranslation } from "react-i18next";
import NavigationTabs from "../NavigationTabs/NavigationTabs";
import NavigationTabsforUser from "../NavigationTabs/NavigationTabsforUser";
import CircularSpinner from "../spinners/circular";

import CloseIcon from "@material-ui/icons/Close";
import { bulletinService } from "../../services/bulletinService";
import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";
import history from "../../utils/history";
import { VOTING_PATH } from "../Routes/var_PATHS";
import AddVoting from "./AddVoting";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import BulletinVoting from "./BulletinVoting";
import BtnBlock from "./BtnBlock";
import AgendaComponent from "./AgendaComponent";
import ResultSection from "./ResultSection";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[4],
    // borderRadius: 20,
    fontSize: 18
  }
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.background.paper,
    borderRadius: 5,
    width: "100%",
    margin: "30px auto ",
    padding: "15px"
    // cursor: "pointer",
  },
  spinner: {
    paddingTop: 110
  },
  infScroll: {
    width: "100%",
    // position: "absolute",
    // height: "max-content !important",
    overflow: "visible !important"
  },
  user: {
    margin: "45px auto",
    // width: "90%",
    minWidth: 375
  },
  admin: {}
}));

function VotingPage(props: any) {
  const { i18n } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const matches890 = useMediaQuery("(min-width:890px)");
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const bulletinId: string = String(
    history.location.pathname.split("/").pop()
  );

  const [bulletin, setBulletin] = useState({
    createdAt: 0,
    createdBy: {
      authProvider: { id: 0, name: "" },

      createdAt: 0,
      email: "",
      firstName: "",
      hashId: "",
      id: 0,
      isDeleted: false,
      isItMe: true,
      lastName: "",
      lastSeenAt: 0,
      modifiedAt: 0,
      pictureUrl: "",
      region: { id: 0, name: "" },
      role: { id: 1, name: "" },
      service: { id: 1, name: "" },
      status: { id: 1, name: "" },
      termsAccepted: false,
      theme: { id: 1, name: "" }
    },
    endDate: 0,
    hashId: "",
    id: 0,
    modifiedAt: 0,
    note: "",
    protocolDate: 0,
    protocolNumber: 0,
    startDate: 0,
    headerText: "",
    bodyText: "",
    votingItems: [
      {
        body: "",
        createdAt: 0,
        hashId: "",
        id: 0,
        isVotedByMe: false,
        modifiedAt: 0,
        note: "",
        options: [
          {
            createdAt: 0,
            hashId: "",
            id: 0,
            modifiedAt: 0,
            name: ""
          }
        ],
        subject: "",
        threshold: 0,
        urls: [
          {
            hashId: "",
            name: "",
            url: "",
            votingHashId: ""
          }
        ],
        result: {}
      }
    ],
    status: { id: 0, name: "" },
    bulletinType: { id: 0, name: "" }
  });

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  useEffect(() => {
    bulletinService
      .getBulletinArchiveByHashId(bulletinId)
      .then((data: any) => setBulletin(data))
      .then(() => setIsLoaded(true));
  }, [bulletinId]);

  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const isAdmin = myRole === Role.Admin;

  const getUpdateVoting = () => {
    bulletinService
      .getBulletinArchiveByHashId(bulletinId)
      .then((data: any) => setBulletin(data));
  };

  const updateBulletin = () => {
    bulletinService
      .getBulletinArchiveByHashId(bulletinId)
      .then((data: any) => setBulletin(data));
  };



  return (
    <Grid
      container
      item
      xs={12}
      justify={"space-between"}
      direction={"row"}
      wrap="nowrap"
    >
      <Grid item style={{ display: matches890 ? "" : "none" }}>
        {isAdmin || myRole === Role.Assistant ? <NavigationTabs /> : <NavigationTabsforUser />}
      </Grid>

      {isLoaded ? (
        <Grid container item xs={12} className={classes.user}>
          <Grow in>
            <>
              <Grid container item xs={12}>
                <BulletinVoting
                  bulletin={bulletin}
                  updateBulletin={updateBulletin}
                />
              </Grid>

              <Grid
                container
                item
                xs={12}
                direction="column"
                justify="center"
                alignItems="flex-end"
                style={{ margin: "20px 0" }}
                wrap='nowrap'
              >
                <Grid container item xs={6}>
                  <Typography variant="h6" align="left">
                    {bulletin.headerText}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  style={{ margin: "10px 0" }}
                >
                  <Typography variant="h6">
                    {`Протокол №${bulletin.protocolNumber
                      } від ${new Date(
                        bulletin.protocolDate
                      ).toLocaleDateString("de", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit"
                      })}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ margin: "20px 0" }}
              >
                <Typography variant="h6" align="center">
                  БЮЛЕТЕНЬ ДЛЯ {(bulletin.bulletinType.name).toUpperCase()}
                </Typography>
              </Grid>

              <Grid container item xs={12} justify="center">
                <Typography align="center" variant="h6">
                  {bulletin.bodyText}
                </Typography>
              </Grid>

              {bulletin.votingItems.length > 0 && (
                <Grid
                  container
                  item
                  xs={12}
                  justify="flex-start"
                  style={{ marginTop: 20 }}
                >
                  <Typography align="left" variant="h6">
                    {bulletin.status.id === 7 || bulletin.status.id === 9 ? "Результат голосування:" : "Порядок денний:"}
                  </Typography>
                </Grid>
              )}


              {bulletin.status.id >= 7 && <>
                {bulletin.votingItems.map((votingItem, index) => (
                  <Grid
                    container
                    item
                    xs={12}
                    key={votingItem.hashId}

                  >
                    <ResultSection
                      voting={votingItem}
                      result={votingItem.result}
                      hashId={votingItem.hashId}
                      votingNumber={index + 1}


                    />
                  </Grid>
                ))}

              </>

              }

              <Grid
                container
                item
                xs={12}
                className={classes.card}
                justify="space-between"
              >
                <Grid container item xs={12} justify='center'>
                  <Typography variant='h6' color='primary' > Результат сформовано автоматично.</Typography>
                  <Box width="100%" />
                  <Typography variant='h6' color='primary' > Підрахунок виконується програмою.</Typography>
                </Grid>
              </Grid>
            </>
          </Grow>

        </Grid>
      ) : (
        <Grid container justify="center" className={classes.spinner}>
          <CircularSpinner />
        </Grid>
      )}
    </Grid>
  );
}

export default withSnackbar(VotingPage);
