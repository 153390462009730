/* eslint-disable valid-typeof */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useMemo, Fragment } from "react";
import clsx from "clsx";
import {
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
  useTheme,
  IconButton,
  Tooltip,
  withStyles,
  InputBase,
  Box,
  Chip,
  Divider,
} from "@material-ui/core";
import CircularSpinner from "../spinners/circular";
import EditedVoting from "./EditedVoting";
import EditIcon from "@material-ui/icons/Edit";
import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";
import { VotingType } from "../../models/voting.model";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    position: "relative",
    left: -10,
    padding: "5px",
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    paddingTop: 110
  },
  infScroll: {
    width: "100%",
    // position: "absolute",
    // height: "max-content !important",
    overflow: "visible !important"
  },
  user: {
    border: `2px solid ${theme.palette.background.paper}`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    padding: 10
  },
  admin: {},

  votingItem: {
    cursor: "pointer",
    minHeight: 48,
    width: "100%",
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,

    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`
    }
  },

  selectedItem: {
    cursor: "pointer",
    minHeight: 48,
    width: "100%",
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 8px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out"
  },

  disabledItem: {
    minHeight: 48,
    width: "100%",
    border: `2px solid ${theme.palette.grey[600]}`,
    backgroundColor: theme.palette.grey[600],
    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 1px ${theme.palette.grey[600]}`,
    transition: "0.2s ease-in-out"
  }
}));



const AgendaComponent = (props: any) => {
  const theme = useTheme();
  const classes = useStyles();

  const {
    subject,
    body,
    note,
    threshold,
    createdAt,
    modifiedAt,
    id,
    hashId,
    isVotedByMe,
    options,
    urls,
    votedItems,
    votingType,
    personalVotedBulletinId
  } = props.voting;

  const [isEdited, setIsEdited] = useState(false);

  const handleEditVoting = () => {
    setIsEdited(!isEdited);
  };

  const [selectedVotings, setSelected] = useState([0]);
  const [disabled, setDisabled] = useState(false);


  const countSelectedItem = selectedVotings.filter(
    (item: any) => Boolean(item)
  ).length;

  const [selectedHashIds, setSelectedHashIds] = useState([''])


  const handleVotingreq = () => {

    const VotingReq = {
      votingHashId: props.voting.hashId,
      optionHashIds: selectedHashIds
        .filter((item: any) => Boolean(item))
        .reduce(function (a: any, b: any) { if (a.indexOf(b) < 0) a.push(b); return a; }, [])
    }


    props.handleVotingReq(VotingReq)

  }

  useEffect(() => {
    if (props.bulletinStatusId >= 7) {
      setDisabled(true)
    }
    countSelectedItem >= threshold ? setDisabled(true) : setDisabled(false);
    handleVotingreq()
  }, [countSelectedItem, threshold, selectedHashIds.length, props.bulletinStatusId]);




  const handleSelected = (id: number, hash: any) => {
    !selectedVotings.filter(
      (item: any) => Boolean(item)
    ).includes(Number(id)) && !disabled
      ?
      setSelected((oldVals: any) => [...oldVals.filter(
        (item: any) => Boolean(item)
      ), id])
      : setSelected((oldVals: any) => [
        ...oldVals.filter((item: any) => item !== id)
      ])

    if (selectedHashIds.includes(hash)) {

      setSelectedHashIds((oldVals: any) => [...oldVals.filter((item: any) => item !== hash)])

    } else {
      if (!disabled) {
        setSelectedHashIds((oldVals: any) => [...oldVals, hash])

      }

    }


  };

  const handleOpenLink = (url: string) =>
    url &&
    Object.assign(document.createElement("a"), {
      target: "_blank",
      href: url,
    }).click();

  const getUpdateVoting = () => {
    props.updateVoting();
    handleEditVoting();
  };


  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const isAdmin = myRole === Role.Admin;
  const isAssist = myRole === Role.Assistant;



  return (
    <>
      {hashId ? (
        <>
          {isEdited && (isAdmin || isAssist) && props.bulletinStatusId < 3 && (
            <EditedVoting
              getUpdateVoting={getUpdateVoting}
              voting={props.voting}
              votingNumber={props.votingNumber}
              bulletinId={props.bulletinId}
            />
          )}

          <Grid
            container
            item
            xs={12}
            justify="flex-end"
            style={{ marginTop: "45px" }}>
            <Grid>
              {props.bulletinStatusId < 3 && (isAdmin || isAssist) && <LightTooltip
                placement="left"
                enterDelay={200}
                leaveDelay={200}
                title={votingType && votingType.id === VotingType.Voting.valueOf() ? "Редагувати блок голосування" : "Редагувати блок опитування"}
              >
                <IconButton onClick={handleEditVoting}>
                  <EditIcon />
                </IconButton>
              </LightTooltip>}
            </Grid>
          </Grid>
          <div

            className={classes.user}
            style={{
              borderColor:
                countSelectedItem >= threshold || isVotedByMe || props.bulletinStatusId > 3
                  ? theme.palette.primary.main
                  : theme.palette.background.paper
            }}>

            {votingType && (
              <Grid container item xs={12} justify="space-between">
                {votingType && votingType.id === VotingType.Voting.valueOf() &&
                  <Chip variant="outlined" label={props.votingNumber} color="primary" />
                }
                {votingType && votingType.id === VotingType.Voting.valueOf() &&
                  <Chip variant="outlined" label={votingType.name} color="primary" />
                }
                {votingType && votingType.id === VotingType.Poll.valueOf() &&
                  <Chip variant="outlined" label={props.votingNumber} color="secondary" />
                }
                {votingType && votingType.id === VotingType.Poll.valueOf() &&
                  <Chip variant="outlined" label={votingType.name} color="secondary" />
                }
              </Grid>
            )}

            {props.votingNumber && (
              <Grid item>
                <Typography variant="h6">
                  {`${subject && subject}`}
                </Typography>
              </Grid>
            )}

            {body && (
              <Grid item style={{ margin: "10px 0" }}>
                <InputBase fullWidth multiline type='text' readOnly value={body} inputProps={{ 'aria-label': 'naked' }} />
              </Grid>
            )}

            {note && (
              <Grid item>
                <Typography>{note}</Typography>
              </Grid>
            )}

            {!isVotedByMe && urls.filter((item: any) => Boolean(item)).length > 0 && (
              <Grid container item xs={12}>
                <Typography>Посилання:</Typography>
                {urls.map((item: any) => (
                  <Grid container item key={item.hashId}>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => handleOpenLink(item.url)}>
                      {item.name} &rarr;
                    </Link>
                  </Grid>
                ))}
              </Grid>
            )}

            {isVotedByMe ? <Grid container item xs={12}>
              {personalVotedBulletinId &&
                <Grid>
                  <Typography align='right' variant='caption'> {`Унікальний ідентифікатор персонального бюлетню: ${personalVotedBulletinId}`} </Typography>
                </Grid>
              }
              <Grid container item xs={12}>
                Було проголосовано за:
              </Grid>
              {votedItems.votedOptionsWithVoteHashId.map((a: any) => (
                <Grid container item xs={12} key={a.hashId}>
                  <Typography color='primary' variant='h6' >{`${a.votedOption.optionNumber}. ${a.votedOption.name}`}</Typography>
                  <Box width="100%" />
                  <Typography align='right' variant='caption'> {`Унікальний ідентифікатор голосу: ${a.votingVoteHashId}`} </Typography>
                </Grid>
              ))}
            </Grid> : options && (
              <Grid container item xs={12}>
                <Typography>Оберіть {threshold} варіанти:</Typography>
                {options.filter((item: any) => Boolean(item.name.trim())).sort((a: any, b: any) => a.id - b.id).map((item: any, index: any) => (
                  <Fragment key={item.hashId}>

                    {/* {props.withVotingOptionScore && props.bulletin.status.id === 3 &&
                      (<Grid container item xs={12} style={{ marginTop: 30 }} justify='space-around'>
                        <Grid item>
                          <Typography variant='caption'>
                            Зареєстровано користаувачів: <span style={{ fontSize: 16 }}>  {` ${props.bulletin.registeredUsers}`}</span>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='caption'>
                            Проголосувало користувачів: <span style={{ fontSize: 16 }}>{` ${props.bulletin.votedUsers}`}</span>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='caption'>
                            Обрали: <span style={{ fontSize: 16 }}>{` ${item.optionScore}`}</span>
                          </Typography>
                        </Grid>
                      </Grid>
                      )} */}

                    <Grid
                      key={item.hashId}
                      container
                      item
                      xs={12}
                      style={{ margin: 10 }}
                      alignItems="center"
                      direction="row"
                      wrap="nowrap">
                      <Grid item style={{ width: "50px" }}>
                        <Typography variant="h6">{item.optionNumber}.</Typography>
                      </Grid>

                      <Grid
                        item
                        className={props.bulletinStatusId >= 7 ? classes.disabledItem : clsx(
                          disabled &&
                          !selectedVotings.filter(
                            (item: any) => Boolean(item)
                          ).includes(item.id) &&
                          classes.disabledItem,
                          selectedVotings.filter(
                            (item: any) => Boolean(item)
                          ).includes(item.id)
                            ? classes.selectedItem
                            : !disabled
                              ? classes.votingItem
                              : classes.disabledItem
                        )}
                        onClick={() => handleSelected(item.id, item.hashId)}>

                        <Typography
                          variant="h6"
                          style={{ padding: "10px" }}
                          align="center">
                          {item.name}
                        </Typography>
                      </Grid>
                    </Grid>


                  </Fragment>
                ))}
              </Grid>
            )}
          </div>
        </>
      ) : (
        <Grid container justify="center" className={classes.spinner}>
          <CircularSpinner />
        </Grid>
      )
      }
    </>
  );
};

export default AgendaComponent;
