import React, { useState } from "react";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Collapse,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Grow from "@material-ui/core/Grow/Grow";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { userService } from "../../services/userService";
import DeleteBtn from "../ButtonsWithConfirmation/DeleteBtn";
import AdminBtn from "../ButtonsWithConfirmation/AdminBtn";
import UserBtn from "../ButtonsWithConfirmation/UserBtn";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.background.paper,
    // height: 300,
    width: "100%",
    margin: "30px auto 0px",
    // padding: "10px 10px ",
    cursor: "pointer",
  },
  btnStylesWithOutline: {
    minHeight: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,

    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
    },
  },
}));

const LightTextInDisabledTextField = withStyles({
  root: {
    // marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#fefefe", // (default alpha is 0.38)
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      color: "#fefefe90", // (default alpha is 0.38)
    },
  },
})(TextField);

const User = (props: any) => {
  // console.log("props.user: ", props.user);

  const [openObject, setOpenObject] = useState({ hashId: "", isOpen: false });

  const handleOpenObject = (idItem: any) => {
    // setTimeout(() => {
    setOpenObject({ hashId: idItem, isOpen: !openObject.isOpen });
    // }, 150);
  };

  // const theme = useTheme();

  const classes = useStyles();
  const { t } = useTranslation();

  const handleDeleteUser = (hash: any) => {
    userService
      .removeUser(hash)
      .then(() => setOpenObject({ hashId: "", isOpen: false }));
  };

  // console.log("props.user", props.user);

  return (
    <Grow in>
      <>
        <Card
          className={classes.card}
          style={{
            border:
              props.user.copyCounter >= 3
                ? "2px solid red"
                : props.user.copyCounter < 3 && props.user.copyCounter >= 1
                ? "2px solid orange"
                : "",
            width: "100%",
          }}>
          <CardActionArea
            disableRipple={props.openNow ? true : props.user.isDeleted}
            disableTouchRipple={props.openNow ? true : props.user.isDeleted}>
            <Grid
              container
              item
              xs={12}
              onClick={() => handleOpenObject(props.user.hashId)}
              style={{ padding: "10px", display: "flex" }}
              justify="space-between"
              alignItems="center"
              alignContent="center"
              wrap="nowrap">
              <Grid
                item
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "pre-line",
                  flexShrink: 2,
                }}>
                {
                  <Grid
                    container
                    item
                    xs={12}
                    // justify="space-between"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap">
                    <Grid item>
                      <Grid
                        container
                        item
                        xs={12}
                        justify="center"
                        alignContent="center"
                        alignItems="center"
                        style={{
                          width: "110px",
                          height: "110px",
                        }}>
                        <Avatar
                          src={props.user.pictureUrl}
                          style={{
                            width: "90px",
                            height: "90px",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item style={{ marginLeft: 30 }}>
                      <Typography
                        variant="h6"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "pre-line",
                        }}>
                        {props.user.status.id === 1
                          ? `${props.user.firstName} ${props.user.lastName}`
                          : "Запрошення відправлено на email:"}
                      </Typography>
                      <Typography
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "pre-line",
                        }}>
                        {props.user.email}
                      </Typography>
                    </Grid>
                  </Grid>
                }

                {"licence" in props.user && (
                  <Grid
                    container
                    item
                    // justify="space-between"
                    xs={12}
                    alignItems="center"
                    alignContent="center"
                    style={{ marginTop: 10 }}
                    wrap="nowrap">
                    <Grid item>
                      <LightTextInDisabledTextField
                        disabled
                        // label={t("referral.input")}
                        label={t("licences.licenceN")}
                        variant="outlined"
                        style={{ width: 110 }}
                        value={props.user.licence.licence}
                        inputProps={{
                          style: { textAlign: "center", fontSize: 24 },
                          disabled: true,
                          // textAlign: "center",
                        }}
                      />
                    </Grid>
                    <Grid item style={{ marginLeft: 30 }}>
                      <Grid container item xs={12}>
                        <Typography
                          variant="h6"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "pre-line",
                          }}>
                          {props.user.licence.fio}
                        </Typography>
                      </Grid>
                      <Grid container item xs={12}>
                        <Typography
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "pre-line",
                          }}>
                          {props.user.licence.name}
                        </Typography>
                      </Grid>
                      <Grid container item xs={12}>
                        <Typography
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "pre-line",
                          }}>
                          {props.user.licence.contacts}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item style={{ flexShrink: 0 }}>
                { props.user.role.id === 1 && (
                  <Grid container item xs={ 12 }>
                    <Chip
                      color="primary"
                      icon={ <AssignmentIndIcon /> }
                      label={ props.user.role.name }
                    />
                  </Grid>
                ) }
                { props.user.role.id === 3 && (
                  <Grid container item xs={12}>
                    <Chip
                      color="primary"
                      icon={<AssignmentIndIcon />}
                      label={props.user.role.name}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardActionArea>

          {!props.user.isDeleted && (
            <Collapse
              in={props.openNow ? true : openObject.isOpen}
              // in={openObject.isOpen}
              timeout="auto"
              unmountOnExit>
              <CardContent>
                <Grid container item xs={12} justify="space-evenly">
                  <Grid>
                    {!props.user.isItMe && (
                      <DeleteBtn
                        handleDelete={handleDeleteUser}
                        hashId={props.user.hashId}
                      />
                    )}
                  </Grid>

                  {/* MAKE ADMIN */ }
                  <Grid>
                    {props.user.role.id === 2 ? (
                      <AdminBtn hashId={props.user.hashId} />
                    ) : (
                      !props.user.isItMe && (
                        <UserBtn hashId={props.user.hashId} />
                      )
                    )}
                  </Grid>

                  {/* { props.user.status.id === 1 && props.user.role.id === 2 && (
                    <Grid></Grid>
                  ) } */}


                  {/* MAKE ADMIN */ }



                  {props.user.isItMe && (
                    <Grid container item xs justify="center">
                      <Typography variant="h5" align="right">
                        Це я !
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Collapse>
          )}
        </Card>
        <Grid container item xs={ 12 }>
          <Typography
            color='error'
            style={ {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "pre-line",

            } }
          >
            { !props.user.licence.isInGovUaDb && "Свідоцтво видалено з Єдиного Реєстру Нотаріусів" }


          </Typography>
        </Grid>

        {
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              {props.user.isDeleted && (
                <Typography color="error">
                  {props.user.copyCounter >= 3
                    ? t("chips.deletedCopy")
                    : t("chips.deleted")}
                </Typography>
              )}

              <Typography style={{ color: "orange" }}>
                {props.user.copyCounter >= 1 &&
                  props.user.copyCounter < 3 &&
                  `Спроби копіювання: ${props.user.copyCounter}`}
              </Typography>
            </Grid>
            <Grid item>
              {"lastSeenAt" in props.user && (
                <Typography align="right" variant="caption">
                  {`Був онлайн: ${new Date(
                    props.user.lastSeenAt
                  ).toLocaleTimeString([], {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                  })}`}
                </Typography>
              )}
            </Grid>
          </Grid>
        }
      </>
    </Grow>
  );
};

export default User;
