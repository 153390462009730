import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  Avatar,
  Grow,
  Chip,
  withStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import NavigationTabs from "../NavigationTabs/NavigationTabs";
import CircularSpinner from "../spinners/circular";
import { Role } from "../../models/static.model";
import { authService } from "../../services/authService";
import { dataService } from "../../services/dataService";
import { userService } from "../../services/userService";
import { linkToLogin, LOGIN_PATH, RULES_PATH } from "../Routes/var_PATHS";
import NavigationTabsforUser from "../NavigationTabs/NavigationTabsforUser";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { config } from "../../utils/config";
import { NpuError } from "../../models/error.model";
import { InternalError } from "../../models/internal-error.model";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar, useSnackbar } from "notistack";
import ViewedAsUser from "../ButtonsWithConfirmation/ViewedAsUser";

import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    paddingTop: 110,
  },
  container: {
    margin: " 30px auto 20px ",
  },
  btnStylesWithOutline: {
    minHeight: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
    },
  },
  btnStylesWithOutlineApproveAlert: {
    minHeight: 48,
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: 25,
    backgroundColor: theme.palette.error.dark,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
    },
  },
  btnStylesWithOutlineSecondary: {
    minHeight: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
    },
  },
  link: {
    cursor: "pointer",
    transition: "0.2s",
    color: theme.palette.primary.dark,
    "&:hover": {
      color: theme.palette.primary.main,
      textShadow: `0px 0px 8px ${ theme.palette.primary.main }`,
    },
  },
  avatar: {
    width: 120,
    height: 120,
  },
  card: {
    background: theme.palette.background.paper,
    width: "100%",
    padding: 10,
  },
  icon: {
    marginRight: 5,
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: `${theme.palette.action.disabled} !important`,
  },
  adminAndUserMob: {
    margin: "45px auto",
    minWidth: 375,
  },
  user: {
    margin: "65px auto",
    width: "75%",
    minWidth: 375,
  },
}));

const LightTextInDisabledTextField = withStyles({
  root: {
    // marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#fefefe", // (default alpha is 0.38)
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      color: "#fefefe90", // (default alpha is 0.38)
    },
  },
})(TextField);

const AccountPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const matches890 = useMediaQuery("(min-width:890px)");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const [user, setUser] = useState({
    authProvider: { id: 0, name: "" },
    createdAt: 0,
    email: "",
    firstName: "",
    hashId: "",
    id: 0,
    isDeleted: false,
    lastName: "",
    licence: {
      contacts: "",
      createdAt: 0,
      fio: "",
      hashId: "",
      licence: 0,
      modifiedAt: 0,
      name: "",
      region: { id: 0, name: "" },
      service: { id: 0, name: "" },
    },
    modifiedAt: 0,
    pictureUrl: "",
    role: { id: 0, name: "" },
    service: { id: 0, name: "" },
    status: { id: 0, name: "" },
    theme: { id: 0, name: "" },
  });

  const [isLogin, setIsLogin] = useState(false);

  useEffect(
    () => {
      userService
        .getMe()
        .then((dataUser: any) => {
          setUser(dataUser);
        })
        .then(() => setIsLogin(true))
        .catch(
          (err: NpuError) =>
            enqueueSnackbar(err.toString(), {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              variant: "error",
              action: cancelAction,
            })
        );
    },
    // eslint-disable-next-line
    []
  );

  const handleLogout = () => {
    authService
      .logout()
      .then(() => linkToLogin())
      .catch(
        (err: NpuError) =>
          enqueueSnackbar(err.toString(), {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            variant: "error",
            action: cancelAction,
          })
      );
  };
  const [helpMessage, setHelpMessage] = useState("");

  const handleChange = (e: any) => {
    setHelpMessage(e);
  };

  const sendMessageForHelp = () => {
    const req = {
      message: helpMessage,
    };

    Promise.resolve(userService.helpMessage(req))
      .then(() => setHelpMessage(""))
      .then(() =>
        enqueueSnackbar("Повідомлення відправлене", {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          variant: "success",
          action: cancelAction,
        })
      )
      .catch((e: any) =>
        enqueueSnackbar(e.toString(), {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        })
      );
  };

  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const isAdmin = myRole === Role.Admin;


  const handleViewLikeUser = () => {
    userService.getUserRoleTokens().then((data: any) => {

      const ERROR_CODE_PARAM = "errorCode";

      authService
        .login(data.role, data.accessToken, data.refreshToken)
        .then(() => {
          window.location.replace(`${config.feBaseUrl}${RULES_PATH}`);
        })
        .catch((error) => {
          if (error instanceof NpuError) {
            const { errorCode } = error as NpuError;
            const url = `${config.historyBaseName}${LOGIN_PATH}?${ERROR_CODE_PARAM}=${errorCode}`;
            window.location.replace(url);
          } else {
            const url = `${config.historyBaseName}${LOGIN_PATH}?${ERROR_CODE_PARAM}=${InternalError.GeneralError}`;
            // console.log(error);
            window.location.replace(url);
            throw error;
          }
        });
    });
  };

  return (
    <Grid
      container
      item
      xs={12}
      justify={ "space-between" }
      direction={"row"}
      wrap="nowrap"
    >
      <Grid item style={{ display: matches890 ? "" : "none" }}>
        { isAdmin || myRole === Role.Assistant ? <NavigationTabs /> : <NavigationTabsforUser /> }
      </Grid>

      <Grid
        container
        item
        xs={ 12 }
        justify="center"
        className={classes.user}
      >
        {isLogin ? (
          <Grid container item xs={12} justify="flex-start">
            <Grow in>
              <>
                <Grid
                  container
                  item
                  xs={ 12 }
                  className={classes.card}
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid container item xs={12} justify="center">
                    {user.role.id === 1 && (
                      <Grid>
                        <Chip
                          color="primary"
                          icon={<AssignmentIndIcon />}
                          label={user.role.name}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      item
                      xs={12}
                      // justify="flex-start"
                      alignContent="center"
                      alignItems="center"
                    >
                      <Avatar
                        src={user.pictureUrl}
                        style={{
                          width: "120px",
                          height: "120px",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{
                      marginLeft: 30,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "pre-line",
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {`${user.firstName} ${user.lastName}`}
                    </Typography>
                    <Typography
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {user.email}
                    </Typography>
                  </Grid>

                  {"licence" in user && (
                    <Grid
                      container
                      item
                      // justify="space-between"
                      xs={12}
                      alignItems="center"
                      alignContent="center"
                      style={{ marginTop: 10 }}
                      wrap="nowrap"
                    >
                      <Grid item>
                        <LightTextInDisabledTextField
                          disabled
                          // label={t("referral.input")}
                          label={t("licences.licenceN")}
                          variant="outlined"
                          style={{ width: 120 }}
                          value={user.licence.licence}
                          inputProps={{
                            style: { textAlign: "center", fontSize: 24 },
                            disabled: true,
                            // textAlign: "center",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          marginLeft: 30,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Grid container item xs={12}></Grid>
                        <Typography
                          variant="h5"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {user.licence.fio}
                        </Typography>
                        <Grid container item xs={12}>
                          <Typography
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {user.licence.name}
                          </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                          <Typography
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {user.licence.contacts}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            </Grow>

            <Grid
              container
              item
              xs={12}
              justify="space-evenly"
              style={{ marginTop: 40 }}
            >
              { myRole !== Role.User && (
                <Grid>
                  <ViewedAsUser handleViewLikeUser={handleViewLikeUser} />
                </Grid>
              )}
              <Grid>
                <Button
                  className={classes.btnStylesWithOutlineSecondary}
                  style={{ minWidth: 220, margin: "10px 0px" }}
                  // variant="text"
                  onClick={handleLogout}
                >
                  <Typography>{t("profile.logout")}</Typography>
                </Button>
              </Grid>
            </Grid>

            <Grid container item xs={12} style={{ marginTop: 80 }}>
              <Grid container item xs={ 12 } justify="center">

              </Grid>
            </Grid>

            {myRole === Role.User && (
              <Grid container item xs={12} style={{ marginTop: 80 }}>
                <Grid container item xs={12} justify="center">
                  <Grid style={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      label={t("profile.helpMessage")}
                      margin="dense"
                      variant="outlined"
                      multiline
                      rows={5}
                      rowsMax="20"
                      value={helpMessage}
                      onChange={(e: any) => handleChange(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} justify="space-evenly">
                  <Grid>
                    <Button
                      disabled={helpMessage.length < 2}
                      className={classes.btnStylesWithOutline}
                      style={{ minWidth: 220, marginTop: 15, marginBottom: 50 }}
                      onClick={sendMessageForHelp}
                      startIcon={<SendIcon />}
                    >
                      <Typography>{t("btns.send")}</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container justify="center" className={classes.spinner}>
            <CircularSpinner />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withSnackbar(AccountPage);
