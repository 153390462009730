import { cryptoStorage } from "../utils/cryptoStorage";
import Logger from "../utils/logger";
import {
  AuthToken,
  JwtToken,
  SessionToken,
  RefreshToken,
  EmptyToken,
} from "../models/token.model";
import { Role } from "../models/static.model";

class DataService {
  private SESSION_TOKEN_KEY: string = "NPU_SESSION_TOKEN";
  private REFRESH_TOKEN_KEY: string = "NPU_REFRESH_TOKEN";
  private ROLE: string = "NPU_ROLE";
  private sessionToken: AuthToken;
  private refreshToken: AuthToken;
  private role: Role;

  private logger: Logger = new Logger(this.constructor.name);

  constructor(prefetchValues: Boolean = false) {
    this.sessionToken = new AuthToken(new EmptyToken());
    this.refreshToken = new AuthToken(new EmptyToken());
    this.role = Role.Empty;
    if (prefetchValues) {
      this.getSessionToken();
      this.getRefreshToken();
      this.getRole();
    }
  }

  setRole(role: string): Promise<Role> {
    if (role === "admin") {
      return cryptoStorage.setItem(this.ROLE, role).then(() => {
        this.logger.debug("Admin role has been set:", role);
        this.role = Role.Admin;
        return this.role;
      });
    } else if (role === "user") {
      return cryptoStorage.setItem(this.ROLE, role).then(() => {
        this.logger.debug("User role has been set:", role);
        this.role = Role.User;
        return this.role;
      });
    }
    else if (role === "assistant") {
      return cryptoStorage.setItem(this.ROLE, role).then(() => {
        this.logger.debug("Assistant role has been set:", role);
        this.role = Role.Assistant;
        return this.role;
      });
    }
    else {
      return cryptoStorage.setItem(this.ROLE, role).then(() => {
        this.logger.debug("unknown role has been set:", role);
        this.role = Role.Unknown;
        return this.role;
      });
    }
  }

  getRole(): Promise<Role> {
    if (this.role === Role.Empty) {
      return cryptoStorage.getItem(this.ROLE).then((role: string) => {
        if (role === "admin") {
          this.logger.debug("role found:", role);
          this.role = Role.Admin;
          return this.role;
        } else if (role === "user") {
          this.logger.debug("role found:", role);
          this.role = Role.User;
          return this.role; 
        } else if (role === "assistant") {
          this.logger.debug("role found:", role);
          this.role = Role.Assistant;
          return this.role;
        } else if (role === null) {
          this.logger.debug("role not found");
          this.role = Role.Empty;
          return this.role;
        } else {
          this.logger.debug("role found bun unknown:", role);
          this.role = Role.Unknown;
          return this.role;
        }
      });
    } else {
      return Promise.resolve(this.role);
    }
  }

  getRoleSync(): Role {
    if (this.role === Role.Empty) {
      const role: string = cryptoStorage.getItemSync(this.ROLE);
      if (role === "admin") {
        this.logger.debug("role found:", role);
        this.role = Role.Admin;
        return this.role;
      } else if (role === "user") {
        this.logger.debug("role found:", role);
        this.role = Role.User;
        return this.role;
      } else if (role === "assistant") {
        this.logger.debug("role found:", role);
        this.role = Role.Assistant;
        return this.role;
      } else if (role === null) {
        this.logger.debug("role not found");
        this.role = Role.Empty;
        return this.role;
      } else {
        this.logger.debug("role found bun unknown:", role);
        this.role = Role.Unknown;
        return this.role;
      }
    } else {
      return this.role;
    }
  }

  setSessionToken(token: JwtToken): Promise<AuthToken> {
    return cryptoStorage.setItem(this.SESSION_TOKEN_KEY, token).then(() => {
      this.logger.debug("session token has been set:", token);
      this.sessionToken = new AuthToken(new SessionToken(), token);
      return this.sessionToken;
    });
  }

  getSessionTokenSync(): AuthToken {
    if (this.sessionToken.tokenType instanceof EmptyToken) {
      const token: any = cryptoStorage.getItemSync(this.SESSION_TOKEN_KEY);
      if (token === null) {
        this.logger.warn("(sync) session token not found");
        return new AuthToken(new EmptyToken());
      } else {
        this.logger.debug("(sync) session token found:", token);
        this.sessionToken = new AuthToken(new SessionToken(), token);
        return this.sessionToken;
      }
    } else {
      return this.sessionToken;
    }
  }

  getSessionToken(): Promise<AuthToken> {
    if (this.sessionToken.tokenType instanceof EmptyToken) {
      return cryptoStorage
        .getItem(this.SESSION_TOKEN_KEY)
        .then((token: any) => {
          if (token === null) {
            this.logger.warn("session token not found");
            return new AuthToken(new EmptyToken());
          } else {
            this.logger.debug("session token found:", token);
            this.sessionToken = new AuthToken(new SessionToken(), token);
            return this.sessionToken;
          }
        });
    } else {
      return Promise.resolve(this.sessionToken);
    }
  }

  setRefreshToken(token: JwtToken): Promise<AuthToken> {
    return cryptoStorage.setItem(this.REFRESH_TOKEN_KEY, token).then(() => {
      this.logger.debug("refresh token has been set:", token);
      this.refreshToken = new AuthToken(new RefreshToken(), token);
      return this.refreshToken;
    });
  }

  getRefreshToken(): Promise<AuthToken> {
    if (this.refreshToken.tokenType instanceof EmptyToken) {
      return cryptoStorage
        .getItem(this.REFRESH_TOKEN_KEY)
        .then((token: any) => {
          if (token === null) {
            this.logger.warn("refresh token not found");
            return new AuthToken(new EmptyToken());
          } else {
            this.logger.debug("refresh token found:", token);
            this.refreshToken = new AuthToken(new RefreshToken(), token);
            return this.refreshToken;
          }
        });
    } else {
      return Promise.resolve(this.refreshToken);
    }
  }

  clear(): Promise<void> {
    return cryptoStorage.clear().then(() => {
      // this.sessionToken = new AuthToken(new EmptyToken());
      // this.refreshToken = new AuthToken(new EmptyToken());
      this.role = Role.Empty;
      // localStorageAsync.setItem("i18nextLng", "ua");
      this.logger.debug("all data has been cleared");
    });
  }
}

export const dataService = new DataService(true);
