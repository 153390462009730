import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  makeStyles,
  useTheme,
  withStyles,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NpuError } from "../../models/error.model";

import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar, withSnackbar } from "notistack";
import { bulletinService } from "../../services/bulletinService";
import { BulletinQuorumType, RegisteredNotariesSourceType } from "../../models/voting.model";

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: "blur(7px)",
    backgroundColor: `${theme.palette.grey[100]}30`,
  },
  dates: {
    marginTop: '10px',
  }
}));


const LightTextInDisabledTextField = withStyles({
  root: {
    // marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#fefefe60", // (default alpha is 0.38)
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      color: "#fefefe90", // (default alpha is 0.38)
    },
  },
})(TextField);

const EditedBulletin = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => (
    <IconButton onClick={() => closeSnackbar()} >
      <CloseIcon fontSize="small" />
    </IconButton>
  );


  const [open, setOpen] = React.useState(true);


  // const [voting, setVoting] = useState({
  //   subject: '',
  //   body: '',
  //   threshold: 0,
  //   options: [],
  //   bulletinHashId: '',
  //   note: '',
  //   urls: [],
  // });

  const [newBulletin, setNewBulletin] = useState({
    protocolNumber: props.bulletin.protocolNumber,
    note: props.bulletin.note,
    headerText: props.bulletin.headerText,
    bodyText: props.bulletin.bodyText,
    protocolDate: props.bulletin.protocolDate,
    startDate: props.bulletin.startDate,
    endDate: props.bulletin.endDate,
    hashId: props.bulletin.hashId
  });

  const handleChange = (name: any, value: any) => {
    if (name === "protocolNumber") {
      const protocol = Number(value.trim().replace(/\D/gim, ""));
      setNewBulletin((oldValue: any) => ({
        ...oldValue,
        protocolNumber: protocol,
      }));
    }


    if (name === "note") {
      setNewBulletin((oldValue: any) => ({ ...oldValue, note: value }));
    }
    if (name === "headerText") {
      setNewBulletin((oldValue: any) => ({ ...oldValue, headerText: value }));
    }
    if (name === "bodyText") {
      setNewBulletin((oldValue: any) => ({ ...oldValue, bodyText: value }));
    }

  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    props.getUpdateBulletin()
  };

  const updateBulletin = () => {

    const registeredNotariesRequest: number | null =
      registeredNotariesSourceTypeId == RegisteredNotariesSourceType.Registry.valueOf() ?
        null : registeredNotaries;

    let registeredNotariesSourceRequest: string | null = null;
    if (registeredNotariesSourceTypeId == RegisteredNotariesSourceType.Registry.valueOf()) {
      registeredNotariesSourceRequest = null;
    } else if (registeredNotariesSourceTypeId == RegisteredNotariesSourceType.Manual.valueOf() && registeredNotariesSource == "") {
      registeredNotariesSourceRequest = null;
    } else {
      registeredNotariesSourceRequest = registeredNotariesSource;
    }

    const reqData = {
      protocolNumber: newBulletin.protocolNumber,
      note: newBulletin.note,
      headerText: newBulletin.headerText,
      bodyText: newBulletin.bodyText,
      protocolDate: newBulletin.protocolDate,
      startDate: newBulletin.startDate,
      endDate: newBulletin.endDate,
      hashId: props.bulletin.hashId,
      registeredNotariesSourceTypeId: registeredNotariesSourceTypeId,
      registeredNotaries: registeredNotariesRequest,
      registeredNotariesSource: registeredNotariesSourceRequest,
      bulletinTypeId: bulletinTypeId
    };

    bulletinService
      .editBulletinInfo(reqData)
      .then(() => handleClose())
      .then(() => props.getUpdateBulletin())
      .catch((e: NpuError) =>
        enqueueSnackbar(e.message, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        })
      );
  };

  const [newStartDate, setNewStartDate] = useState(new Date(props.bulletin.startDate))
  const [newEndDate, setNewEndDate] = useState(new Date(props.bulletin.endDate))

  const handleProtocolDateChange = (e: any) => {
    if (e.target.name === "protocolDate") {
      const date = new Date(e.target.value);
      const dateLong = date.getTime();
      setNewBulletin((oldValue: any) => ({ ...oldValue, protocolDate: dateLong }));
    }
  }


  const handleStartDateChange = (e: any) => {
    if (e.target.name === "startDate") {
      const date = new Date(e.target.value);
      const dateLong = date.getTime();
      setNewBulletin((oldValue: any) => ({ ...oldValue, startDate: dateLong }));
      setNewStartDate(date)
    }
  }

  const handleEndDateChange = (e: any) => {
    if (e.target.name === "endDate") {
      const date = new Date(e.target.value);
      const dateLong = date.getTime();
      setNewBulletin((oldValue: any) => ({ ...oldValue, endDate: dateLong }));
      setNewEndDate(date)
    }
  }

  const dateToString = (date: Date, dropTime: Boolean): String => {
    const localDate = date.toLocaleString('sv', { timeZoneName: 'short', timeZone: "Europe/Kiev" });
    const yyyymmdd = localDate.split(" ")[0]
    if (dropTime) {
      return yyyymmdd
    } else {
      const timeParts = (localDate.split(" ")[1]).split(":");
      const hh = timeParts[0].length == 1 ? "0" + timeParts[0] : timeParts[0];
      const hhmm = hh + ":" + timeParts[1];
      return yyyymmdd + "T" + hhmm;
    }
  }

  const [registeredNotariesSourceTypeId, setRegisteredNotariesSourceTypeId] = useState<number>(props.bulletin.stats.registeredNotariesSourceType.id)
  const handleRegisteredNotariesSourceTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRegisteredNotariesSourceTypeId(event.target.value as number);
    if (RegisteredNotariesSourceType.Manual.valueOf() == event.target.value as number) {
      setRegisteredNotaries(1);
      setRegisteredNotariesSource("");
    }
  };

  const [registeredNotaries, setRegisteredNotaries] = useState<number>(props.bulletin.stats.registeredNotaries)
  const changeSetRegisteredNotariesManually = (e: any) => {
    const newRegisteredNotiaries = e.target.value
    if (newRegisteredNotiaries <= 1) {
      setRegisteredNotaries(1)
    } else {
      setRegisteredNotaries(Number(newRegisteredNotiaries))
    }
  }

  const [registeredNotariesSource, setRegisteredNotariesSource] = useState<string>(props.bulletin.stats.registeredNotariesSource)
  const handleRegisteredNotariesSourceChange = (e: any) => {
    const newRegisteredNotiariesSource = e.target.value
    setRegisteredNotariesSource(newRegisteredNotiariesSource)
  }

  const [bulletinTypeId, setBulletinTypeId] = useState<number>(props.bulletin.bulletinType.id)
  const handleBulletinTypeIdChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBulletinTypeId(event.target.value as number);
  };

  return (
    <>
      <Grid container item xs={12} justify="center">
        <Dialog
          maxWidth="md"
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
            timeout: 400,
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
        >
          {/* <DialogTitle id="form-dialog-title">{t("help.label")}</DialogTitle> */}
          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: theme.palette.grey[900] }}
          >
            {/* {t("licences.modalTitle")} */}
            Редагувати бюлетень
          </DialogTitle>
          <DialogContent
            dividers={true}
            style={{ backgroundColor: theme.palette.grey[900] }}
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {/* <DialogContentText>{t("betaTest.titleFeedback")}</DialogContentText>   */}

            <Grid container item xs={12} >
              <FormControl margin="dense" variant="outlined" fullWidth>
                <InputLabel id="bulletin-type-select-label">Тип *</InputLabel>
                <Select
                  labelId="bulletin-select-label"
                  id="bulletin-type-select"
                  variant="outlined"
                  value={bulletinTypeId}
                  onChange={handleBulletinTypeIdChange}
                >
                  <MenuItem value={0}>Голосування</MenuItem>
                  <MenuItem value={1}>Опитування</MenuItem>
                  <MenuItem value={2}>Голосування та Опитування</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid container item xs={12} >
              <LightTextInDisabledTextField
                disabled={props.bulletin.status.id >= 2}
                label='Заголовок'
                // label={t("licences.licenceN")}
                name="headerText"
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="10"
                value={newBulletin.headerText}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }

              // inputProps={{ disabled: true }}
              />


              <Grid container item xs={12} direction="row"
                justify="space-between"
                alignItems="stretch"
                style={{ margin: '20px 0' }}

              >
                <Grid item xs={5}>

                  <LightTextInDisabledTextField
                    disabled={props.bulletin.status.id >= 2}
                    multiline
                    rows={2}
                    rowsMax="5"
                    error={newBulletin.protocolNumber < 1}
                    label='Номер протоколу'
                    // label={t("licences.licenceN")}
                    name="protocolNumber"
                    fullWidth
                    margin='dense'
                    variant="outlined"

                    value={newBulletin.protocolNumber}
                    onChange={(e: any) =>
                      handleChange(e.target.name, e.target.value)
                    }

                  // inputProps={{ disabled: true }}
                  />
                </Grid>
                <Grid item xs={5}>

                  <form

                    // noValidate
                    style={{ width: "100%", marginTop: 9 }}
                  >
                    <LightTextInDisabledTextField
                      disabled={props.bulletin.status.id >= 2}
                      fullWidth
                      required
                      error={newBulletin.protocolDate > 100 ? false : true}
                      style={{ width: "100%" }}
                      id="protocolDate"
                      type="date"
                      name="protocolDate"
                      label='Дата протоколу'
                      // label={ t("addresses.date") }
                      variant="outlined"
                      rows="5"
                      // rowsMax="20"
                      // multiline
                      // defaultValue={today()}
                      value={dateToString(new Date(newBulletin.protocolDate), true)}
                      onChange={(e: any) => {
                        handleProtocolDateChange(e);
                      }}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                </Grid>

                {/* type="datetime-local" */}



              </Grid>


              <LightTextInDisabledTextField

                disabled={props.bulletin.status.id >= 2}
                label='Основний текст'
                // label={t("licences.licenceN")}
                name="bodyText"
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="10"
                value={newBulletin.bodyText}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }

              // inputProps={{ disabled: true }}
              />




              <Grid container item xs={12} direction="row"
                justify="space-between"
                alignItems="stretch"
                style={{ margin: '20px 0' }}
              >
                <Grid item xs={5}>
                  <Grid item xs={12} container className={classes.dates}>
                    <form

                      // noValidate
                      style={{ width: "100%" }}
                    >
                      <LightTextInDisabledTextField
                        disabled={props.bulletin.status.id >= 2}
                        fullWidth
                        required
                        error={newBulletin.startDate > 100 ? false : true}
                        style={{ width: "100%" }}
                        id="startDate"
                        type="datetime-local"
                        name="startDate"
                        // label={ t("addresses.date") }
                        label='Початок голосування'
                        variant="outlined"
                        rows="5"
                        // rowsMax="20"
                        // multiline
                        // defaultValue={today()}
                        // value={ newBulletin.startDate}
                        // "2017-06-10T08:30";
                        value={dateToString(newStartDate, false)}

                        onChange={(e: any) => {
                          handleStartDateChange(e);
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </form>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <Grid item xs={12} container className={classes.dates}>
                    <form

                      // noValidate
                      style={{ width: "100%", }}
                    >
                      <LightTextInDisabledTextField
                        fullWidth
                        required
                        error={newBulletin.endDate > 100 ? false : true}
                        style={{ width: "100%" }}
                        id="endDate"
                        type="datetime-local"
                        name="endDate"
                        // label={ t("addresses.date") }
                        label='Кінець голосування'
                        variant="outlined"
                        rows="5"
                        // rowsMax="20"
                        // multiline
                        // defaultValue={today()}
                        value={dateToString(newEndDate, false)}
                        onChange={(e: any) => {
                          handleEndDateChange(e);
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </form>
                  </Grid>
                </Grid>
              </Grid>





              <LightTextInDisabledTextField
                // label={t("licences.pib")}
                disabled={props.bulletin.status.id >= 2}
                label="Нотатки"
                name="note"
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={2}
                rowsMax="5"
                placeholder="нотатки..."
                value={newBulletin.note}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }

              // inputProps={{ disabled: true }}
              />

              <FormControl margin="dense" variant="outlined" fullWidth>
                <InputLabel id="quorum-select-label">Кількість зареєстрованих нотаріусів *</InputLabel>
                <Select
                  labelId="quorum-select-label"
                  id="voting-type-select"
                  variant="outlined"
                  value={registeredNotariesSourceTypeId}
                  onChange={handleRegisteredNotariesSourceTypeChange}
                >
                  <MenuItem value={0}>Взяти з Єдиного Реєстру Нотаріусів України</MenuItem>
                  <MenuItem value={1}>Ввести власноруч</MenuItem>
                </Select>
              </FormControl>

              {registeredNotariesSourceTypeId === RegisteredNotariesSourceType.Manual.valueOf() && (
                <>
                  <Grid container item xs={12} style={{ marginTop: 0 }} >
                    <Grid container item xs={12}>
                      <Typography>Kiлькiть зареєстрованих нотаріусів</Typography>
                    </Grid>
                    <Grid container item xs={12}>

                      <TextField
                        onChange={changeSetRegisteredNotariesManually}
                        variant='outlined'
                        autoFocus
                        margin="dense"
                        type='number'
                        fullWidth
                        value={registeredNotaries}
                      />

                    </Grid>
                  </Grid>
                  <Grid container item xs={12} >
                    <TextField
                      label='Джерело'
                      name="headerText"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      multiline
                      rows={1}
                      rowsMax="10"
                      value={registeredNotariesSource}
                      onChange={(e: any) => handleRegisteredNotariesSourceChange(e)}
                    />
                  </Grid>
                </>
              )}


            </Grid>
          </DialogContent>
          <DialogActions style={{ backgroundColor: theme.palette.grey[900] }}>
            <Grid container item xs={12} justify="space-between">
              <Grid>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="primary"
                  startIcon={<CancelIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  <Typography>{t("btns.cancel")}</Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  onClick={updateBulletin}
                  color="primary"
                  startIcon={<SendIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  {/* <Typography>{ t("btns.send") }</Typography> */}
                  <Typography>Зберегти</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default withSnackbar(EditedBulletin);
