import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography,
} from "@material-ui/core";
import history from "../../utils/history";
import {
  LICENSES_PATH,
  USERS_PATH,
  SETTINGS_PATH,
  UNAUTHORIZED_PATH,
  linkToLicenses,
  linkToUsers,
  linkToUnauthorized,
  linkToSettings,
  REQUESTS_PATH,
  linkToRequests,
  linkToVoting,
  VOTING_PATH,
  linkToArchive,
  ARCHIVE_PATH,
} from "../Routes/var_PATHS";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { localStorageAsync } from "../../utils/localStorageAsync";
import LockIcon from "@material-ui/icons/Lock";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AllInboxIcon from '@material-ui/icons/AllInbox';
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { dataService } from "../../services/dataService";
import { Role } from "../../models/static.model";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    position: "relative",
    left: -10,
    padding: "5px",
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) => ({
  btnStyles: {
    textTransform: "uppercase",
    cursor: "pointer",
    fontSize: "16px",
    lineHeight: "40px",
    border: `0px solid `,
    borderRadius: 0,
    transition: "0.2s ease-in-out",
    maxWidth: 'max-content',
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:active": {
      color: theme.palette.primary.main,
    },
  },
  arrowsBack: {
    transitionDuration: "0.8s",
    transitionProperty: "transform",
    transform: "rotate(0deg)",
    
  },
  arrows: {
    transitionDuration: "0.8s",
    transitionProperty: "transform",
    transform: "rotate(180deg)",
  },
  menuFull: {
    transitionDuration: "0.3s",
    width: 200,
    marginRight: 50,
    transition: "width 0.2s",
  },
  menuMini: {
    transitionDuration: "0.3s",
    width: 80,
    marginRight: 30,
    transition: "width 0.2s",
  },
}));

export default function NavigationTabs() {
  const classes = useStyles();

  const { t } = useTranslation();

  const getMenuStyle = localStorageAsync.getItemSync("ms") === "true";

  const [open, setOpen] = useState(getMenuStyle);

  const handleClick = () => {
    localStorageAsync.setItem("ms", !open);
    setOpen(!open);
  };

  const myRole = dataService.getRoleSync()


  return (
    <Grid container item className={open ? classes.menuFull : classes.menuMini}>
      <div style={{ position: "relative" }}>
        <Grid
          container
          item
          justify="flex-start"
          direction={"column"}
          style={{ position: "fixed", top: 100, width: open ? 200 : 'max-content' }}
        >
          <IconButton
            className={open ? classes.arrows : classes.arrowsBack}
            edge="start"
            size="medium"
            style={{
              cursor: "pointer",
              width: 60,
              height: 60,
              marginBottom: 20,
            }}
            onClick={handleClick}
          >
            <DoubleArrowIcon />
          </IconButton>



          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToVoting}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? "Голосування" : ""}
                // title={!open ? t("btns.search") : ""}
              >
                <HowToVoteIcon
                  color={
                    history.location.pathname === VOTING_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={450}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === VOTING_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {/* {t("btns.search")} */}
                   Голосування
                </Typography>
              </Grid>
            </Slide>
          </Grid>


          <Grid
            container
            item
            direction="row"
            className={ classes.btnStyles }
            onClick={ linkToArchive }
          >
            <span
              style={ {
                position: "relative",
                top: 4,
                paddingRight: 10,
              } }
            >
              <LightTooltip
                placement="right"
                enterDelay={ 200 }
                leaveDelay={ 200 }
                title={ !open ? "Архів" : "" }
              // title={!open ? t("btns.search") : ""}
              >
                <AllInboxIcon
                  color={
                    history.location.pathname === ARCHIVE_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={ "right" }
              timeout={ 400 }
              in={ open }
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={ classes.btnStyles }
                  color={
                    history.location.pathname === ARCHIVE_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {/* {t("btns.search")} */ }
                  Архів
                </Typography>
              </Grid>
            </Slide>
          </Grid>




          { myRole === Role.Admin && <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToLicenses}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("licences.btnName") : ""}
              >
                <ReceiptIcon
                  color={
                    history.location.pathname === LICENSES_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={500}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === LICENSES_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("licences.btnName")}
                </Typography>
              </Grid>
            </Slide>
          </Grid> }

          { myRole === Role.Admin && <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToUsers}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("users.btnName") : ""}
              >
                <PeopleAltIcon
                  color={
                    history.location.pathname === USERS_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={550}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === USERS_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("users.btnName")}
                </Typography>
              </Grid>
            </Slide>
          </Grid> }



          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToRequests}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("requests.btnName") : ""}
              >
                <PlaylistAddIcon
                  color={
                    history.location.pathname === REQUESTS_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={650}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === REQUESTS_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("requests.btnName")}
                </Typography>
              </Grid>
            </Slide>
          </Grid>
          { myRole === Role.Admin &&
          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToUnauthorized}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("unauthorized.btnName") : ""}
              >
                <LockIcon
                  color={
                    history.location.pathname === UNAUTHORIZED_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={700}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === UNAUTHORIZED_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("unauthorized.btnName")}
                </Typography>
              </Grid>
            </Slide>
            </Grid> }

          <Grid
            container
            item
            direction="row"
            className={classes.btnStyles}
            onClick={linkToSettings}
          >
            <span
              style={{
                position: "relative",
                top: 4,
                paddingRight: 10,
              }}
            >
              <LightTooltip
                placement="right"
                enterDelay={200}
                leaveDelay={200}
                title={!open ? t("profile.btnName") : ""}
              >
                <ContactMailIcon
                  color={
                    history.location.pathname === SETTINGS_PATH
                      ? "primary"
                      : "inherit"
                  }
                />
              </LightTooltip>
            </span>

            <Slide
              direction={"right"}
              timeout={750}
              in={open}
              mountOnEnter
              unmountOnExit
            >
              <Grid>
                <Typography
                  className={classes.btnStyles}
                  // variant="text"
                  color={
                    history.location.pathname === SETTINGS_PATH
                      ? "primary"
                      : "inherit"
                  }
                >
                  {t("profile.btnName")}
                </Typography>
              </Grid>
            </Slide>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
