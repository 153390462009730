import { UnknownEvent } from "./error.model";

export interface RcmdEvent {
  id: number;
  name: string;
}
export class UserUpdatedEvent implements RcmdEvent {
  id: number = 1;
  name: string = "UserUpdatedEvent";
}

export class ObjectUpdatedEvent implements RcmdEvent {
  id: number = 2;
  name: string = "AddressUpdatedEvent";
}

export class ObjectEventAddedEvent implements RcmdEvent {
  id: number = 3;
  name: string = "AddressEventAddedEvent";
}

export class LicenceUpdatedEvent implements RcmdEvent {
  id: number = 4;
  name: string = "LicenceUpdatedEvent";
}

export class NotAuthorizedAccessAddedEvent implements RcmdEvent {
  id: number = 5;
  name: string = "NotAuthorizedAccessAddedEvent";
}

export class SignUpRequestUpdatedEvent implements RcmdEvent {
  id: number = 14;
  name: string = "SignUpRequestUpdatedEvent";
}

export class LogOutUserEvent implements RcmdEvent {
  id: number = 6;
  name: string = "LogOutUserEvent";
}
export class BulletinUpdatedEvent implements RcmdEvent {
  id: number = 17;
  name: string = "BulletinUpdatedEvent";
}

export function resolveEvent(message: any): RcmdEvent {
  if (message.id === 11) {
    return new UserUpdatedEvent();
  } else if (message.id === 10) {
    return new ObjectUpdatedEvent();
  } else if (message.id === 6) {
    return new ObjectEventAddedEvent();
  } else if (message.id === 12) {
    return new LicenceUpdatedEvent();
  } else if (message.id === 17) {
    return new BulletinUpdatedEvent();
  } else if (message.id === 14) {
    return new SignUpRequestUpdatedEvent();
  } else if (message.id === 13) {
    return new NotAuthorizedAccessAddedEvent();
  } else if (message.id === 1 || message.id === 2) {
    return new LogOutUserEvent();
  } else {
    throw new UnknownEvent(message.id);
  }
}

export const AUTH_TOPIC = "AUTH-TOPIC";
export const USER_TOPIC = "USER-TOPIC";
export const OBJECT_TOPIC = "OBJECT-TOPIC";
export const REQUEST_TOPIC = "REQUEST-TOPIC";
export const LICENCE_TOPIC = "LICENCE-TOPIC";
export const OBJECT_EVENT_TOPIC = "OBJECT-EVENT-TOPIC";
export const BULLETIN_TOPIC = "BULLETIN_TOPIC";
