import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button,
  TextField,
  Typography,
  makeStyles,
  Theme,
  useTheme,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Grow from "@material-ui/core/Grow/Grow";
import { NpuError } from "../../models/error.model";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar, useSnackbar } from "notistack";
import { bulletinService } from "../../services/bulletinService";
import { RegisteredNotariesSourceType } from "../../models/voting.model";

const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: "blur(7px)",
    backgroundColor: `${theme.palette.grey[100]}30`,
  },
  dates: {
    marginTop: '10px',
  }
}));

const AddBulletin = (props: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const [open, setOpen] = React.useState(false);

  const [newBulletin, setNewBulletin] = useState({
    protocolNumber: 0,
    note: "",
    headerText: 'Затверджено рішенням Правління відділення НПУ в м.Києві',
    bodyText: " на загальних зборах нотаріусів міста Києва  шляхом дистанційного електронного голосування",
    protocolDate: Date.now(),
    startDate: Date.now(),
    endDate: Date.now(),
    quorumTypeId: 0,
    quorum: 1,
    quorumSource: "",
  });

  const handleChange = (name: any, value: any) => {
    if (name === "protocolNumber") {
      const protocol = Number(value.trim().replace(/\D/gim, ""));
      setNewBulletin((oldValue: any) => ({
        ...oldValue,
        protocolNumber: protocol,
      }));
    }


    if (name === "note") {
      setNewBulletin((oldValue: any) => ({ ...oldValue, note: value }));
    }
    if (name === "headerText") {
      setNewBulletin((oldValue: any) => ({ ...oldValue, headerText: value }));
    }
    if (name === "bodyText") {
      setNewBulletin((oldValue: any) => ({ ...oldValue, bodyText: value }));
    }

  }

  const handleClickOpen = () => {
    setOpen(true);
    // console.log("open : ", open);
  };

  const handleClose = () => {
    setOpen(false);

    setNewBulletin({
      protocolNumber: 0,
      note: "",
      headerText: 'Затверджено рішенням Правління відділення НПУ в м.Києві',
      bodyText: " на загальних зборах нотаріусів міста Києва  шляхом дистанційного електронного голосування",
      protocolDate: Date.now(),
      startDate: Date.now(),
      endDate: Date.now(),
      quorumTypeId: 0,
      quorum: 1,
      quorumSource: "",
    });
  };

  const sendNewBulletin = () => {

    const registeredNotariesRequest: number | null =
      registeredNotariesSourceTypeId === RegisteredNotariesSourceType.Registry.valueOf() ?
        null : registeredNotaries;

    let registeredNotariesSourceRequest: string | null = null;
    if (registeredNotariesSourceTypeId === RegisteredNotariesSourceType.Registry.valueOf()) {
      registeredNotariesSourceRequest = null;
    } else if (registeredNotariesSourceTypeId === RegisteredNotariesSourceType.Manual.valueOf() && registeredNotariesSource == "") {
      registeredNotariesSourceRequest = null;
    } else {
      registeredNotariesSourceRequest = registeredNotariesSource;
    }


    const reqData = {
      protocolNumber: newBulletin.protocolNumber,
      note: newBulletin.note,
      headerText: newBulletin.headerText,
      bodyText: newBulletin.bodyText,
      protocolDate: newBulletin.protocolDate,
      startDate: newBulletin.startDate,
      endDate: newBulletin.endDate,
      registeredNotariesSourceTypeId: registeredNotariesSourceTypeId,
      registeredNotaries: registeredNotariesRequest,
      registeredNotariesSource: registeredNotariesSourceRequest,
      bulletinTypeId: bulletinTypeId
    };

    bulletinService
      .createBulletin(reqData)
      .then(() => handleClose())
      .then(() => props.getUpdateVotings())
      .catch((e: NpuError) =>
        enqueueSnackbar(e.message, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        })
      );
  };



  const [newStartDate, setNewStartDate] = useState(new Date())
  const [newEndDate, setNewEndDate] = useState(new Date())


  const handleChangeDate = (name: any, val: any, e: any) => {



    if (name === "protocolDate") {
      setNewBulletin((oldValue: any) => ({ ...oldValue, protocolDate: val }));

    }

    if (name === "startDate") {
      setNewBulletin((oldValue: any) => ({ ...oldValue, startDate: val }));
      setNewStartDate(e.target.value)

    }
    if (name === "endDate") {
      setNewBulletin((oldValue: any) => ({ ...oldValue, endDate: val }));
      setNewEndDate(e.target.value)
    }
  };

  const [registeredNotariesSourceTypeId, setRegisteredNotariesSourceTypeId] = useState<number>(0)
  const handleRegisteredNotariesSourceTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRegisteredNotariesSourceTypeId(event.target.value as number);
  };

  const [registeredNotaries, setRegisteredNotaries] = useState<number>(1)
  const changeSetRegisteredNotariesManually = (e: any) => {
    const newRegisteredNotiaries = e.target.value
    if (newRegisteredNotiaries <= 1) {
      setRegisteredNotaries(1)
    } else {
      setRegisteredNotaries(Number(newRegisteredNotiaries))
    }
  }

  const [registeredNotariesSource, setRegisteredNotariesSource] = useState<string>("")
  const handleRegisteredNotariesSourceChange = (e: any) => {
    const newRegisteredNotiariesSource = e.target.value
    setRegisteredNotariesSource(newRegisteredNotiariesSource)
  }

  const [bulletinTypeId, setBulletinTypeId] = useState<number>(0)
  const handleBulletinTypeIdChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBulletinTypeId(event.target.value as number);
  };

  return (
    <>
      <Grow in>
        <Grid>
          <IconButton
            onClick={handleClickOpen}
            color="primary"
            style={{ width: 80, height: 80 }}
          >
            <AddCircleIcon fontSize="large" style={{ width: 60, height: 60 }} />
          </IconButton>
        </Grid>
      </Grow>

      <Grid container item xs={12} justify="center">
        <Dialog
          maxWidth="md"
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
            timeout: 400,
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: theme.palette.grey[900] }}
          >
            Створити бюлетень
          </DialogTitle>
          <DialogContent
            dividers={true}
            style={{ backgroundColor: theme.palette.grey[900] }}
            id="scroll-dialog-description"
            tabIndex={-1}
          >

            <Grid container item xs={12} >
              <FormControl margin="dense" variant="outlined" fullWidth>
                <InputLabel id="bulletin-type-select-label">Тип *</InputLabel>
                <Select
                  labelId="bulletin-select-label"
                  id="bulletin-type-select"
                  variant="outlined"
                  value={bulletinTypeId}
                  onChange={handleBulletinTypeIdChange}
                >
                  <MenuItem value={0}>Голосування</MenuItem>
                  <MenuItem value={1}>Опитування</MenuItem>
                  <MenuItem value={2}>Голосування та Опитування</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid container item xs={12} >
              <TextField
                label='Заголовок'
                name="headerText"
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="10"
                value={newBulletin.headerText}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }
              />


              <Grid container item xs={12} direction="row"
                justify="space-between"
                alignItems="stretch"
                style={{ margin: '20px 0' }}

              >
                <Grid item xs={5}>
                  <TextField
                    multiline
                    rows={2}
                    rowsMax="5"
                    error={newBulletin.protocolNumber < 1}
                    label='Номер протоколу'
                    name="protocolNumber"
                    fullWidth
                    margin='dense'
                    variant="outlined"

                    value={newBulletin.protocolNumber}
                    onChange={(e: any) =>
                      handleChange(e.target.name, e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={5}>

                  <form
                    style={{ width: "100%", marginTop: 9 }}
                  >
                    <TextField
                      fullWidth
                      required
                      error={newBulletin.protocolDate > 100 ? false : true}
                      style={{ width: "100%" }}
                      id="protocolDate"
                      type="date"
                      name="protocolDate"
                      label='Дата протоколу'
                      variant="outlined"
                      rows="5"
                      onChange={(e: any) => {
                        const a = String(new Date(e.target.value));
                        const newDate = Date.parse(a);

                        handleChangeDate(e.target.name, newDate, e);
                      }}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                </Grid>
              </Grid>


              <TextField
                label='Основний текст'
                name="bodyText"
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={1}
                rowsMax="10"
                value={newBulletin.bodyText}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }
              />

              <Grid container item xs={12} direction="row"
                justify="space-between"
                alignItems="stretch"
                style={{ margin: '20px 0' }}
              >
                <Grid item xs={5}>
                  <Grid item xs={12} container className={classes.dates}>
                    <form
                      style={{ width: "100%" }}
                    >
                      <TextField
                        fullWidth
                        required
                        error={newBulletin.startDate > 100 ? false : true}
                        style={{ width: "100%" }}
                        id="startDate"
                        type="datetime-local"
                        name="startDate"
                        label='Початок голосування'
                        variant="outlined"
                        rows="5"
                        onChange={(e: any) => {
                          const a = String(new Date(e.target.value));
                          const newDate = Date.parse(a);
                          handleChangeDate(e.target.name, newDate, e);
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </form>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <Grid item xs={12} container className={classes.dates}>
                    <form
                      style={{ width: "100%", }}
                    >
                      <TextField
                        fullWidth
                        required
                        error={newBulletin.endDate > 100 ? false : true}
                        style={{ width: "100%" }}
                        id="endDate"
                        type="datetime-local"
                        name="endDate"
                        label='Кінець голосування'
                        variant="outlined"
                        rows="5"
                        onChange={(e: any) => {
                          const a = String(new Date(e.target.value));
                          const newDate = Date.parse(a);

                          handleChangeDate(e.target.name, newDate, e);
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </form>
                  </Grid>
                </Grid>
              </Grid>

              <TextField
                label="Нотатки"
                name="note"
                fullWidth
                margin="dense"
                variant="outlined"
                multiline
                rows={2}
                rowsMax="5"
                placeholder="нотатки..."
                value={newBulletin.note}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }
              />

              <FormControl margin="dense" variant="outlined" fullWidth>
                <InputLabel id="quorum-select-label">Кількість зареєстрованих нотаріусів *</InputLabel>
                <Select
                  labelId="quorum-select-label"
                  id="voting-type-select"
                  variant="outlined"
                  value={registeredNotariesSourceTypeId}
                  onChange={handleRegisteredNotariesSourceTypeChange}
                >
                  <MenuItem value={0}>Взяти з Єдиного Реєстру Нотаріусів України</MenuItem>
                  <MenuItem value={1}>Ввести власноруч</MenuItem>
                </Select>
              </FormControl>

              {registeredNotariesSourceTypeId === RegisteredNotariesSourceType.Manual.valueOf() && (
                <>
                  <Grid container item xs={12} style={{ marginTop: 0 }} >
                    <Grid container item xs={12}>
                      <Typography>Kiлькiть зареєстрованих нотаріусів</Typography>
                    </Grid>
                    <Grid container item xs={12}>

                      <TextField
                        onChange={changeSetRegisteredNotariesManually}
                        variant='outlined'
                        autoFocus
                        margin="dense"
                        type='number'
                        fullWidth
                        value={registeredNotaries}
                      />

                    </Grid>
                  </Grid>
                  <Grid container item xs={12} >
                    <TextField
                      label='Джерело'
                      name="headerText"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      multiline
                      rows={1}
                      rowsMax="10"
                      value={registeredNotariesSource}
                      onChange={(e: any) => handleRegisteredNotariesSourceChange(e)}
                    />
                  </Grid>
                </>
              )}

            </Grid>
          </DialogContent>
          <DialogActions style={{ backgroundColor: theme.palette.grey[900] }}>
            <Grid container item xs={12} justify="space-between">
              <Grid>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="primary"
                  startIcon={<CancelIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  <Typography>{t("btns.cancel")}</Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  onClick={sendNewBulletin}
                  color="primary"
                  startIcon={<SendIcon />}
                  style={{ minWidth: 180, margin: "5px 0px" }}
                >
                  {/* <Typography>{ t("btns.send") }</Typography> */}
                  <Typography>Зберегти</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default withSnackbar(AddBulletin);
