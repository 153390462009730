import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar, withSnackbar } from "notistack";
import { authService } from "../../services/authService";
import {
  Divider,
  Typography,
  ListItemIcon,
  ListItem,
  List,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CloseIcon from "@material-ui/icons/Close";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import ReceiptIcon from "@material-ui/icons/Receipt";
import HowToVoteIcon from "@material-ui/icons/HowToVote";
import LockIcon from "@material-ui/icons/Lock";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import AllInboxIcon from '@material-ui/icons/AllInbox';
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import {
  linkToLogin,
  linkToMain,
  linkToLicenses,
  linkToUsers,
  linkToSettings,
  linkToUnauthorized,
  linkToRequests,
  linkToVoting,
  linkToArchive
} from "../Routes/var_PATHS";
import ImageAvatars from "./ImageAvatars";
import { dataService } from "../../services/dataService";
import { Role } from "../../models/static.model";

const HeaderMenuDropDown = (props: any) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={ () => closeSnackbar() }>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    authService
      .logout()
      .then(() => handleClose())
      .then(() => linkToLogin())
      .catch((err: any) =>
        enqueueSnackbar(err.toString(), {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          variant: "error",
          action: cancelAction,
        })
      );
  };

  const handleLicenses = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    linkToLicenses();
    handleClose();
  };

  const handleUsers = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    linkToUsers();
    handleClose();
  };

  const handleRequests = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    linkToRequests();
    handleClose();
  };

  const handleUnauthorizedUsers = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    linkToUnauthorized();
    handleClose();
  };

  const handleSettings = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    linkToSettings();
    handleClose();
  };

  const handleVoting = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    linkToVoting();
    handleClose();
  };

  const handleArchive = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    linkToArchive();
    handleClose();
  };

  const myRole = dataService.getRoleSync()

  return (
    <>
      <IconButton
        aria-controls="avatar-menu"
        aria-haspopup="true"
        disableRipple
        disableFocusRipple
        color="primary"
        onClick={ handleClick }
      >
        <ImageAvatars
          email={ props.email }
          firstName={ props.firstName }
          lastName={ props.lastName }
          pictureUrl={ props.pictureUrl }
        />
      </IconButton>

      <Menu
        style={ { top: 40 } }
        disableAutoFocusItem
        id="simple-menu"
        anchorEl={ anchorEl }
        keepMounted
        open={ Boolean(anchorEl) }
        onClose={ handleClose }
        variant="menu"
      >

        <MenuItem onClick={ handleVoting }>
          <List style={ { margin: 0, padding: 0 } }>
            <ListItem>
              <ListItemIcon>
                <HowToVoteIcon />
              </ListItemIcon>
              <Typography>Голосування</Typography>
            </ListItem>
          </List>
        </MenuItem>
        <Divider />


        {/* Role.Admin * Role.Admin * Role.Admin */ }

        { myRole === Role.Admin && (
          <div>
            <MenuItem onClick={ handleArchive }>
              <List style={ { margin: 0, padding: 0 } }>
                <ListItem>
                  <ListItemIcon>
                    <AllInboxIcon />
                  </ListItemIcon>
                  <Typography>Архів</Typography>
                </ListItem>
              </List>
            </MenuItem>
            <Divider />
          </div>
        ) }



        { myRole === Role.Admin && (
          <div>
            <MenuItem onClick={ handleLicenses }>
              <List style={ { margin: 0, padding: 0 } }>
                <ListItem>
                  <ListItemIcon>
                    <ReceiptIcon />
                  </ListItemIcon>
                  <Typography>{ t("licences.btnName") }</Typography>
                </ListItem>
              </List>
            </MenuItem>

            <Divider />
          </div>
        ) }

        { myRole === Role.Admin && (
          <div>
            <MenuItem onClick={ handleUsers }>
              <List style={ { margin: 0, padding: 0 } }>
                <ListItem>
                  <ListItemIcon>
                    <PeopleAltIcon />
                  </ListItemIcon>
                  <Typography>{ t("users.btnName") }</Typography>
                </ListItem>
              </List>
            </MenuItem>

            <Divider />
          </div>
        ) }

        { myRole === Role.Admin && (
          <div>
            <MenuItem onClick={ handleRequests }>
              <List style={ { margin: 0, padding: 0 } }>
                <ListItem>
                  <ListItemIcon>
                    <PlaylistAddIcon />
                  </ListItemIcon>
                  <Typography>{ t("requests.btnName") }</Typography>
                </ListItem>
              </List>
            </MenuItem>

            <Divider />
          </div>
        ) }


        { myRole === Role.Admin && (
          <div>
            <MenuItem onClick={ handleUnauthorizedUsers }>
              <List style={ { margin: 0, padding: 0 } }>
                <ListItem>
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  <Typography>{ t("unauthorized.btnName") }</Typography>
                </ListItem>
              </List>
            </MenuItem>

            <Divider />
          </div>
        ) }

        {/* Role.Admin * Role.Admin * Role.Admin */ }


        {/* Role.Assistant * Role.Assistant * Role.Assistant */ }

        { myRole === Role.Assistant && (
          <div>
            <MenuItem onClick={ handleArchive }>
              <List style={ { margin: 0, padding: 0 } }>
                <ListItem>
                  <ListItemIcon>
                    <AllInboxIcon />
                  </ListItemIcon>
                  <Typography>Архів</Typography>
                </ListItem>
              </List>
            </MenuItem>
            <Divider />
          </div>
        ) }

        { myRole === Role.Assistant && (
          <div>
            <MenuItem onClick={ handleRequests }>
              <List style={ { margin: 0, padding: 0 } }>
                <ListItem>
                  <ListItemIcon>
                    <PlaylistAddIcon />
                  </ListItemIcon>
                  <Typography>{ t("requests.btnName") }</Typography>
                </ListItem>
              </List>
            </MenuItem>

            <Divider />
          </div>
        ) }

        {/* Role.Assistant * Role.Assistant * Role.Assistant */ }



        <MenuItem onClick={ handleSettings }>
          <List style={ { margin: 0, padding: 0 } }>
            <ListItem>
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
              <Typography>{ t("profile.btnName") }</Typography>
            </ListItem>
          </List>
        </MenuItem>
        <Divider />

        <MenuItem onClick={ handleLogout }>
          <List style={ { margin: 0, padding: 0 } }>
            <ListItem>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <Typography> { t("profile.logout") }</Typography>
            </ListItem>
          </List>
        </MenuItem>
      </Menu>
    </>
  );
};

export default withSnackbar(HeaderMenuDropDown);
