import Logger from "../utils/logger";
import {
  User,
  AccessLog,
  ThemeRequest,
  AddUserRequest,
  UserFilter,
  AccessLogFilter,
  HelpRequest,
  TokensResponse,
} from "../models/user.model";
import { http } from "./httpService";
import { PagedResult } from "../models/profile.models";
import { config } from "../utils/config";

export class UserService {
  private logger = new Logger(this.constructor.name);

  getMe(): Promise<User> {
    const path = `${config.beBaseUrl}/api/v1/user/me`;
    this.logger.debug("get me request sent");
    return http.get<User>(path).then((resp: User) => {
      this.logger.debug("get me response:", resp);
      return resp;
    });
  }

  acceptTerms(): Promise<User> {
    const path = `${config.beBaseUrl}/api/v1/user/me/terms`;
    this.logger.debug("accept terms request sent");
    return http.get<User>(path).then((resp: User) => {
      this.logger.debug("accept terms response:", resp);
      return resp;
    });
  }

  getMyUserIsDeletedFlag(): Promise<User> {
    const path = `${config.beBaseUrl}/api/v1/user/me/is/deleted`;
    this.logger.debug("get me is deleted flag request sent");
    return http.get<User>(path).then((resp: User) => {
      this.logger.debug("get me is deleted response:", resp);
      return resp;
    });
  }

  getUserRoleTokens(): Promise<TokensResponse> {
    const path = `${config.beBaseUrl}/api/v1/user/admin/tokens/user`;
    this.logger.debug("get user role tokens request sent");
    return http.get<TokensResponse>(path).then((resp: TokensResponse) => {
      this.logger.debug("get user role tokens response:", resp);
      return resp;
    });
  }

  getUserById(hashId: string): Promise<User> {
    const path = `${config.beBaseUrl}/api/v1/user/${hashId}`;
    this.logger.debug("get user by id request sent");
    return http.get<User>(path).then((resp: User) => {
      this.logger.debug("get user by id response:", resp);
      return resp;
    });
  }

  makeUserAsAdmin(hashId: string): Promise<User> {
    const path = `${config.beBaseUrl}/api/v1/user/${hashId}/role/admin`;
    this.logger.debug("make user as admin role request sent");
    return http.get<User>(path).then((resp: User) => {
      this.logger.debug("make user as admin role response:", resp);
      return resp;
    });
  }

  makeUserAsAssistant(hashId: string): Promise<User> {
    const path = `${config.beBaseUrl}/api/v1/user/${hashId}/role/assistant`;
    this.logger.debug("make user as assistant role request sent");
    return http.get<User>(path).then((resp: User) => {
      this.logger.debug("make user as assistant role response:", resp);
      return resp;
    });
  }

  makeUserAsUser(hashId: string): Promise<User> {
    const path = `${config.beBaseUrl}/api/v1/user/${hashId}/role/user`;
    this.logger.debug("make user as user role request sent");
    return http.get<User>(path).then((resp: User) => {
      this.logger.debug("make user as user role response:", resp);
      return resp;
    });
  }

  removeUser(hashId: string): Promise<User> {
    const path = `${config.beBaseUrl}/api/v1/user/${hashId}`;
    this.logger.debug("remove user request sent");
    return http.delete<User>(path).then((resp: User) => {
      this.logger.debug("remove user response:", resp);
      return resp;
    });
  }

  addUserToWhiteList(request: AddUserRequest): Promise<User> {
    const path = `${config.beBaseUrl}/api/v1/user/add`;
    this.logger.debug(`add user to white list request: ${request}`);
    return http.post<User>(path, request).then((resp: User) => {
      this.logger.debug("add user to white list response:", resp);
      return resp;
    });
  }

  helpMessage(request: HelpRequest): Promise<User> {
    const path = `${config.beBaseUrl}/api/v1/user/help`;
    this.logger.debug(` help request: ${request}`);
    return http.post<User>(path, request).then((resp: User) => {
      this.logger.debug("help response:", resp);
      return resp;
    });
  }

  searchUsers(filter: UserFilter): Promise<PagedResult<User>> {
    const path = `${config.beBaseUrl}/api/v1/user/search`;
    this.logger.debug(`search user request: ${filter}`);
    return http
      .post<PagedResult<User>>(path, filter)
      .then((resp: PagedResult<User>) => {
        this.logger.debug("search user response:", resp);
        return resp;
      });
  }

  searchAccessLog(filter: AccessLogFilter): Promise<PagedResult<AccessLog>> {
    const path = `${config.beBaseUrl}/api/v1/user/access/log/search`;
    this.logger.debug(`search access-log request: ${filter}`);
    return http
      .post<PagedResult<AccessLog>>(path, filter)
      .then((resp: PagedResult<AccessLog>) => {
        this.logger.debug("search acces-log response:", resp);
        return resp;
      });
  }

  setTheme(themeId: number): Promise<User> {
    const path = `${config.beBaseUrl}/api/v1/me/theme`;
    const themeRequest: ThemeRequest = new ThemeRequest(themeId);
    this.logger.debug(`set theme to me: lang-id: ${themeId}`);
    return http.put<User>(path, themeRequest).then((resp: User) => {
      this.logger.debug("set theme to me response:", resp);
      return resp;
    });
  }
}

export const userService = new UserService();
