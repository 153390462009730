import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useMediaQuery, withStyles } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { withSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { Bulletin, BulletinStatus } from "../../models/bulletin.model";
import { Role } from "../../models/static.model";
import { RegisteredNotariesSourceType, Voting, VotingAttendance, VotingOption, VotingRegistryVote, VotingTypeView } from "../../models/voting.model";
import { dataService } from "../../services/dataService";
import { votingService } from "../../services/votingService";
import history from "../../utils/history";
import NavigationTabs from "../NavigationTabs/NavigationTabs";
import NavigationTabsforUser from "../NavigationTabs/NavigationTabsforUser";
import CircularSpinner from "../spinners/circular";
import VotingAttendaceReportPrint from "./VotingAttendanceReportPrint"

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    paddingTop: 110
  },
}));

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: 15,
  },
}))(Tooltip);

interface IProps { }

function VotingAttendanceReport(props: IProps) {

  const classes = useStyles();

  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const matches980 = useMediaQuery("(max-width:980px)");

  const isAdmin = myRole === Role.Admin;

  const votingHashId: string = String(history.location.pathname.split("/").slice(-2).shift());

  const bulletinHashId: string = String(history.location.pathname.split("/").slice(-4).shift());

  const isBulletinRoutePath: boolean = String(history.location.pathname.split("/").slice(-5).shift()) === "bulletins";

  const isArchiveRoutePath: boolean = String(history.location.pathname.split("/").slice(-5).shift()) === "archive";

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [report, setReport] = useState<Array<VotingRegistryVote>>([]);

  const [bulletin, setBulletin] = useState<Bulletin>()

  const [voting, setVoting] = useState<Voting>({});

  const [scoredOptions, setScoredOptions] = useState<Array<VotingOption>>([]);

  const [votingType, setVotingType] = useState<VotingTypeView>({ id: 0, name: "" });

  const matches890 = useMediaQuery("(min-width:890px)");

  const goToBulletin = (bulletinHashId: string) => { history.push("/bulletins/" + bulletinHashId) };

  const goToArchive = (bulletinHashId: string) => { history.push("/archive/" + bulletinHashId) };

  const goBackToBulletin = (bulletinHashId: string) => {
    isBulletinRoutePath && goToBulletin(bulletinHashId);
    isArchiveRoutePath && goToArchive(bulletinHashId);
  }

  const id = () => { };

  const validateRole = () => { isAdmin ? id() : goBackToBulletin(bulletinHashId); }

  const validateBulletinStatus = (bulletin: Bulletin): Bulletin => {
    bulletin.status.id === BulletinStatus.Completed.valueOf() ||
      bulletin.status.id === BulletinStatus.Archived.valueOf() ? id() : goBackToBulletin(bulletinHashId);
    return bulletin
  }

  const resolveVoting = (bulletin: Bulletin): Voting => {
    const votings: Array<Voting> = bulletin.votingItems;
    const currentVoting = votings.filter((v: Voting) => v.hashId === votingHashId).shift();
    resolveScoredOptions(currentVoting!!);
    return currentVoting!!;
  }

  const resolveScoredOptions = (voting: Voting) => {
    if (voting.result) {
      const sortedOptions = voting.result.scoredOptions
        .sort((a: VotingOption, b: VotingOption) => a.position!! - b.position!!)
      setScoredOptions(sortedOptions);
    } else {
      setScoredOptions([]);
    }
  }

  const resolveVotingType = (bulletin: Bulletin): VotingTypeView => {
    const voting = resolveVoting(bulletin);
    return voting.votingType!!;
  }


  useEffect(() => {
    validateRole();
    if (isArchiveRoutePath) {
      votingService.getVotingArchiveAttendanceReport(bulletinHashId, votingHashId)
        .then((report: VotingAttendance) => {
          validateBulletinStatus(report.bulletin);
          setBulletin(report.bulletin);
          setVoting(resolveVoting(report.bulletin));
          setVotingType(resolveVotingType(report.bulletin));
          setReport(report.attendance);
        })
        .then(() => setIsLoaded(true))
    } else {
      votingService.getVotingAttendanceReport(bulletinHashId, votingHashId)
        .then((report: VotingAttendance) => {
          validateBulletinStatus(report.bulletin);
          setBulletin(report.bulletin);
          setVotingType(resolveVotingType(report.bulletin));
          setVoting(resolveVoting(report.bulletin));
          setReport(report.attendance);
        })
        .then(() => setIsLoaded(true))
    }
  }, []);


  return (
    <Grid
      container
      item
      xs={12}
      justify={"space-between"}
      direction={"row"}
      wrap="nowrap"
    >

      <Grid item style={{ display: matches890 ? "" : "none" }}>
        {isAdmin || myRole === Role.Assistant ? <NavigationTabs /> : <NavigationTabsforUser />}
      </Grid>

      {isLoaded ? (
        <>
          <Grid container item xs={12} style={{ margin: '46px 0' }} >

            <Grid container item xs={12} justify='space-between' style={{ marginTop: '10px' }}>
              <Grid>
                <LightTooltip
                  placement='right'
                  enterDelay={200}
                  leaveDelay={200}
                  title="Назад">
                  <IconButton onClick={() => { goBackToBulletin(bulletinHashId) }}>
                    <ArrowBackIosIcon />
                  </IconButton>
                </LightTooltip>
              </Grid>

              <Grid>
                <VotingAttendaceReportPrint bulletinHashId={bulletinHashId} votingHashId={votingHashId} isArchive={isArchiveRoutePath} />
              </Grid>
            </Grid>

            {bulletin && (
              <Grid container item xs={12} justify="center" >
                <Typography variant='h5'>
                  {`Протокол № ${bulletin.protocolNumber!!} ` + `від ${new Date(bulletin.protocolDate!!).toLocaleString('de-DE', { year: "numeric", month: "2-digit", day: "2-digit", })} року`}
                </Typography>
              </Grid>)}

            <Grid container item xs={12} style={{ margin: "0px 0" }}>
              <Typography variant="h6">
                {`${votingType.name}:`}
              </Typography>
            </Grid>

            {voting && voting.subject && (
              <Grid container item xs={12} style={{ margin: "5px 0" }}>
                <Typography variant="h6">
                  {`${voting.subject!!}`}
                </Typography>
              </Grid>
            )}

            {voting && voting.body && (
              <Grid item style={{ margin: "10px 0" }}>
                <Typography>{voting.body}</Typography>
              </Grid>
            )}

            {voting && voting.note && (
              <Grid container item style={{ margin: "10px 0" }}>
                <Typography variant="caption" >{voting.note}</Typography>
              </Grid>
            )}

            {voting && voting.result && voting.result.quorum && voting.result.registeredNotaries && (
              <>
                <Grid container item xs={12}>
                  <div style={{ width: 150, marginTop: 15 }}>
                    <Typography variant='body1' >
                      Зареєстровано:
                    </Typography>
                  </div>
                  <Typography variant='body1' style={{ marginTop: 15 }}>
                    {`${voting.result.registeredNotaries} `}
                  </Typography>

                  {!matches980 && voting && voting.result && voting.result.registeredNotariesSourceType!!.id === RegisteredNotariesSourceType.Registry.valueOf() &&
                    <Typography variant='caption' style={{ marginLeft: 8, marginTop: 17 }}>
                      {` ( ${voting.result.registeredNotariesSourceType!!.name} ) `}
                    </Typography>
                  }
                  {!matches980 && voting && voting.result && voting.result.registeredNotariesSourceType!!.id === RegisteredNotariesSourceType.Manual.valueOf() &&
                    <Typography variant='caption' style={{ marginLeft: 8, marginTop: 17 }}>
                      {` ( ${voting.result.registeredNotariesSourceType!!.name} - ${voting.result.registeredNotariesSource} ) `}
                    </Typography>
                  }
                </Grid>
                <Grid container item xs={12}>
                  <div style={{ width: 150, marginTop: 0 }}>
                    <Typography variant='body1' >
                      Кворум:
                    </Typography>
                  </div>
                  <Typography variant='body1' style={{ marginTop: 0 }}>
                    {`${voting.result.quorum} `}
                  </Typography>
                  {!matches980 && voting.result &&
                    <Typography variant='caption' style={{ marginLeft: 8, marginTop: 3 }}>
                      {` ( ${voting.result.quorumType!!.name} ) `}
                    </Typography>
                  }
                </Grid>
              </>
            )}

            {voting && voting.result && voting.result.votedUsers &&
              <Grid container item xs={12} >
                <div style={{ width: 150, marginBottom: 10 }}>
                  <Typography variant='body1' >
                    Проголосували:
                  </Typography>
                </div>
                <Typography variant='body1' style={{ marginBottom: 0 }}>
                  {voting.result.votedUsers}
                </Typography>
              </Grid>
            }

            <Grid container item xs={12} justify="center" style={{ margin: "10px 0" }}>
              <Table size="small" aria-label="result-report">
                <TableHead>
                  <TableRow>
                    <TableCell>{"Місце"}</TableCell>
                    <TableCell>{"Варіант"}</TableCell>
                    <TableCell>{"Проголосували"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scoredOptions.map((option: VotingOption) => (
                    <TableRow key={option.hashId} >
                      <TableCell>
                        <Typography variant="body1">
                          {option.position}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {`${option.name}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {`${option.optionScore} ( ${option.percentage} % )`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>



            {report.length !== 0 &&
              (
                <>
                  <Grid container item xs={12} justify="center" style={{ margin: "10px 0" }}>
                    <Typography variant="h5">
                      {`Явка ${voting.votingType!!.name}`}
                    </Typography>
                  </Grid>

                  <Grid container item xs={12} justify="center" style={{ margin: "10px 0" }}>
                    <Table size="small" aria-label="voting-attendace- report">
                      <TableHead>
                        <TableRow>
                          <TableCell>№</TableCell>
                          <TableCell>{"Свідоцтво"}</TableCell>
                          <TableCell>{"ПІБ"}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {report.map((attendace: VotingRegistryVote, index: number) => (
                          <TableRow key={index} >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <Typography variant="body2" display="block" gutterBottom> {attendace.licence} </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" display="block" gutterBottom> {attendace.fio} </Typography>
                            </TableCell>
                          </TableRow>
                        ))}

                      </TableBody>
                    </Table>
                  </Grid>
                </>
              )}

            {report.length === 0 &&
              (<Grid container item xs={12} justify="center" style={{ margin: "60px 0" }}>
                <Typography variant="body1">
                  {"Явку голосування можна переглянути тільки для голосувань які було створено після 1 липня 2024 року."}
                </Typography>
              </Grid>)}

          </Grid>
        </>
      ) : (
        <Grid container justify="center" className={classes.spinner}>
          <CircularSpinner />
        </Grid>
      )}
    </Grid>
  )

}

export default withSnackbar(VotingAttendanceReport);
