import React, { useMemo } from 'react'
import PrintIcon from '@material-ui/icons/Print';
import { withStyles, Theme, Tooltip, IconButton } from '@material-ui/core';
import { Role } from '../../models/static.model';
import { dataService } from '../../services/dataService';
import { config } from '../../utils/config';
import queryString from "query-string";



const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[4],
    // borderRadius: 20,
    fontSize: 15,

  },
}))(Tooltip);


const PrintBulletin = (props: any) => {


  const myRole = useMemo(() => dataService.getRoleSync(), []);


  const isAdmin = myRole === Role.Admin;

  const handlePrintBulletin = (id: any, isArchive: Boolean) => {


    dataService.getSessionToken().then((data: any) => {
      if (isArchive) {

        const path = `${ config.beBaseUrl }/api/v1/bulletin/${ id }/archive/view?authToken=${ data.token }`;

        return window.open(path, '_blank')

      } else {
        const path = `${ config.beBaseUrl }/api/v1/bulletin/${ id }/view?authToken=${ data.token }`;

        return window.open(path, '_blank')
      }

    }

    )


  }

  return (

    <LightTooltip
      placement='left'
      enterDelay={ 200 }
      leaveDelay={ 200 }
      title="Роздрукувати бюлетень">
      <IconButton onClick={ () => handlePrintBulletin(props.id, props.isArchive) }>
        <PrintIcon />
      </IconButton>
    </LightTooltip>



  )
}

export default PrintBulletin