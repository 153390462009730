import React, { FunctionComponent, useMemo } from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import { authService } from "../../services/authService";
import { LOGIN_PATH } from "./var_PATHS";

interface PrivateRouteProps extends RouteProps {
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = (
  props: PrivateRouteProps
) => {
  const { component: Component, render: Render, ...rest } = props;
  const isLogedIn = authService.isLoggedInSync();

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogedIn ? (
          Render ? (
            Render(props)
          ) : (
            Component && <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: LOGIN_PATH,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
