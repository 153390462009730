import { Bulletin, SetRegisteredNotariesRequest } from "../models/bulletin.model";
import {
  AddVotingRequest,
  BulletinVoteRequest,
  DeleteVotingRequest,
  UpdateVotingRequest,
  Voting,
  VotingAttendance,
  VotingAuthlog,
  VotingRegistry,
} from "../models/voting.model";
import { config } from "../utils/config";
import Logger from "../utils/logger";
import { http } from "./httpService";

export class VotingService {
  private logger = new Logger(this.constructor.name);

  addVotingToBulletin(newVotingRequest: AddVotingRequest): Promise<Voting> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/voting`;
    this.logger.debug("add voting to bulletin request:", newVotingRequest);
    return http
      .post<AddVotingRequest>(path, newVotingRequest)
      .then((resp: Voting) => {
        this.logger.debug("add voting to bulletin response:", resp);
        return resp;
      });
  }

  editVoting(updateVotingRequest: UpdateVotingRequest): Promise<Voting> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/voting`;
    this.logger.debug("edit voting request:", updateVotingRequest);
    return http.put<Voting>(path, updateVotingRequest).then((resp: Voting) => {
      this.logger.debug("edit voting response:", resp);
      return resp;
    });
  }

  deleteVoting(deleteVotingRequest: DeleteVotingRequest): Promise<Voting> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/voting/delete`;
    this.logger.debug("delete voting request:", deleteVotingRequest);
    return http.post<Voting>(path, deleteVotingRequest).then((resp: Voting) => {
      this.logger.debug("delete voting response:", resp);
      return resp;
    });
  }

  makeVote(voteRequest: BulletinVoteRequest): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/vote`;
    this.logger.debug("make a vote request:", voteRequest);
    return http.post<Bulletin>(path, voteRequest).then((resp: Bulletin) => {
      this.logger.debug("make a vote response:", resp);
      return resp;
    });
  }


  setBulletinNotaries(request: SetRegisteredNotariesRequest): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/notaries`;
    this.logger.debug("set bulletin notaries request:", request);
    return http.put<Bulletin>(path, request).then((resp: Bulletin) => {
      this.logger.debug("set bulletin notaries response:", resp);
      return resp;
    });
  }

  getVotingRegistryReport(bulletinHashId: string, votingHashId: string): Promise<VotingRegistry> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}/voting/${votingHashId}/report`;
    this.logger.debug("get voting registry report by hash-id");
    return http.get<VotingRegistry>(path).then((resp: VotingRegistry) => {
      this.logger.debug("get voting registry repor by hash-id response:", resp);
      return resp;
    });
  }

  getVotingArchiveRegistryReport(bulletinHashId: string, votingHashId: string): Promise<VotingRegistry> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}/archive/voting/${votingHashId}/report`;
    this.logger.debug("get voting-archive registry report by hash-id");
    return http.get<VotingRegistry>(path).then((resp: VotingRegistry) => {
      this.logger.debug("get voting-archive registry repor by hash-id response:", resp);
      return resp;
    });
  }

  getVotingAttendanceReport(bulletinHashId: string, votingHashId: string): Promise<VotingAttendance> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}/voting/${votingHashId}/report/attendance`;
    this.logger.debug("get voting attendance report by hash-id");
    return http.get<VotingAttendance>(path).then((resp: VotingAttendance) => {
      this.logger.debug("get voting attendance repor by hash-id response:", resp);
      return resp;
    });
  }

  getVotingArchiveAttendanceReport(bulletinHashId: string, votingHashId: string): Promise<VotingAttendance> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}/archive/voting/${votingHashId}/report/attendance`;
    this.logger.debug("get voting-archive attendance report by hash-id");
    return http.get<VotingAttendance>(path).then((resp: VotingAttendance) => {
      this.logger.debug("get voting-archive attendance repor by hash-id response:", resp);
      return resp;
    });
  }

  getVotingAuthlogReport(bulletinHashId: string, votingHashId: string): Promise<VotingAuthlog> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}/voting/${votingHashId}/report/authlog`;
    this.logger.debug("get voting authlog report by hash-id");
    return http.get<VotingAuthlog>(path).then((resp: VotingAuthlog) => {
      this.logger.debug("get voting authlog repor by hash-id response:", resp);
      return resp;
    });
  }

  getVotingArchiveAuthlogReport(bulletinHashId: string, votingHashId: string): Promise<VotingAuthlog> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}/archive/voting/${votingHashId}/report/authlog`;
    this.logger.debug("get voting-archive authlog report by hash-id");
    return http.get<VotingAuthlog>(path).then((resp: VotingAuthlog) => {
      this.logger.debug("get voting-archive authlog repor by hash-id response:", resp);
      return resp;
    });
  }

}

export const votingService = new VotingService();
