import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withSnackbar, useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { debounce } from "lodash";

import {
  Grid,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery
} from "@material-ui/core";
// import { useTranslation } from "react-i18next";
import NavigationTabs from "../NavigationTabs/NavigationTabs";
import NavigationTabsforUser from "../NavigationTabs/NavigationTabsforUser";
import CloseIcon from "@material-ui/icons/Close";
import SearchField from "../Search/SearchField";
import { bulletinService } from "../../services/bulletinService";
import SearchInfo from "../Search/SearchInfo";
import ToTopBtn from "../ButtonsWithConfirmation/ToTopBtn";
import ArchiveBulletinItem from "./ArchiveBulletinItem";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularSpinner from "../spinners/circular";
import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";
import { pubSubService } from "../../services/pubSubService";
import { BulletinUpdatedEvent } from "../../models/event.model";

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    paddingTop: 110
  },
  infScroll: {
    width: "100%",
    // position: "absolute",
    // height: "max-content !important",
    overflow: "visible !important"
  },
  user: {
    margin: "45px auto",
    // width: "90%",
    minWidth: 375
  },
  admin: {}
}));

function ArchivePage() {
  const { i18n } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const matches890 = useMediaQuery("(min-width:890px)");
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);

  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const isAdmin = myRole === Role.Admin;

  const [bulletins, setBulletins] = useState([
    {
      createdAt: 0,
      createdBy: {
        authProvider: { id: 0, name: "" },

        createdAt: 0,
        email: "",
        firstName: "",
        hashId: "",
        id: 0,
        isDeleted: false,
        isItMe: true,
        lastName: "",
        lastSeenAt: 0,
        modifiedAt: 0,
        pictureUrl: "",
        region: { id: 0, name: "" },
        role: { id: 1, name: "" },
        service: { id: 1, name: "" },
        status: { id: 1, name: "" },
        termsAccepted: false,
        theme: { id: 1, name: "" }
      },
      endDate: 0,
      hashId: "",
      id: 0,
      modifiedAt: 0,
      note: "",
      protocolDate: 0,
      protocolNumber: 0,
      startDate: 0,
      //       Заголовок
      // Основний текст
      headerText: "",
      bodyText: "",
      status: { id: 0, name: "" }
    }
  ]);


  const cancelAction = () => {
    return (
      <IconButton onClick={ () => closeSnackbar() }>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const delayedQuery = useCallback(
    debounce((text: any) => {
      onSearch(text);
    }, 300),
    []
  );
  const [searchVal, setSearch] = useState({ field: "" });
  const [paginationData, setPaginationData] = useState({
    pageNumber: Number(),
    pageSize: Number(),
    totalCount: Number()
  });



  const eventHandler = function (msg: any, data: any) {
    if (data instanceof BulletinUpdatedEvent) {

    }
  };




  useEffect(() => {

    pubSubService.requestSubscribe(eventHandler);

    setIsLoaded(true);
    const filter = {
      pageNumber: paginationData.pageNumber || 1,
      pageSize: paginationData.pageSize || 20,
      // licence: 0,
      searchTerm: searchVal.field
      // searchTerm: "ба",
    };

    bulletinService
      .searchBulletinArchive(filter)
      .then((data: any) => {
        setBulletins(data.data);
        // console.log("searchLicence", data);
        return data;
      })
      .then((data: any) =>
        setPaginationData((oldState: any) => ({
          ...oldState,
          pageNumber: filter.pageNumber + 1,
          totalCount: data.totalCount,
          pageSize: data.pageSize
        }))
      )
      .then(() => setIsLoaded(false));

    return () => {
      pubSubService.unsubsribe(eventHandler, "Event-handler");
    };

  }, []);

  const onSearch = (text: any) => {
    try {
      setIsLoaded(true);
      const filterData = {
        pageNumber: 1,
        searchTerm: text
        // pageSize: paginationData.pageSize || 20,
        // licence: 0,
      };

      bulletinService
        .searchBulletinArchive(filterData)
        .then((data: any) => {
          setBulletins(data.data);
          return data;
        })
        .then((data: any) =>
          setPaginationData((oldState: any) => ({
            ...oldState,
            pageNumber: filterData.pageNumber + 1,
            totalCount: data.totalCount,
            pageSize: data.pageSize
          }))
        )
        .then(() => setIsLoaded(false));
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        variant: "error",
        action: cancelAction
      });
    }
  };

  const getSearch = (e: any) => {
    const searchText = e.trim();

    setSearch((searchVal: any) => ({
      ...searchVal,
      field: searchText
    }));
    delayedQuery(searchText);
  };

  const getUpdateVotings = () => {
    setIsLoaded(true);
    const filter = {
      pageNumber: 1,
      pageSize: paginationData.pageSize || 20,
      // licence: 0,
      searchTerm: searchVal.field
      // searchTerm: "ба",
    };

    bulletinService
      .searchBulletinArchive(filter)
      .then((data: any) => {
        setBulletins(data.data);
        // console.log("searchLicence", data);
        return data;
      })
      .then((data: any) =>
        setPaginationData((oldState: any) => ({
          ...oldState,
          pageNumber: filter.pageNumber + 1,
          totalCount: data.totalCount,
          pageSize: data.pageSize
        }))
      )
      .then(() => setIsLoaded(false));
  };

  const fetchMoreData = () => {
    if (paginationData.totalCount > bulletins.length) {
      try {
        const filter = {
          pageNumber: paginationData.pageNumber || 1,
          pageSize: paginationData.pageSize || 20,
          searchTerm: searchVal.field
        };

        bulletinService
          .searchBulletinArchive(filter)
          .then((data: any) => {
            setBulletins((oldBulletins: any) =>
              [...oldBulletins].concat(data.data)
            );

            return data;
            // console.log("userService.searchUsers", data);
          })
          .then((data: any) =>
            setPaginationData((oldState: any) => ({
              ...oldState,
              pageNumber: filter.pageNumber + 1,
              totalCount: data.totalCount,
              pageSize: data.pageSize
            }))
          )
          .then(() => setIsLoaded(false));
      } catch (err) {
        enqueueSnackbar(err.toString(), {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          },
          variant: "error",
          action: cancelAction
        });
      }
    }
  };

  return (
    <>
      <Grid
        container
        item
        xs={ 12 }
        justify={ "space-between" }
        // justify={isAdmin ? "space-between" : "flex-start"}
        direction={ "row" }
        wrap="nowrap">
        <Grid item style={ { display: matches890 ? "" : "none" } }>
          { isAdmin || myRole === Role.Assistant ? <NavigationTabs /> : <NavigationTabsforUser /> }
        </Grid>

        <Grid container item xs={ 12 } className={ classes.user }>
          <Grid container item xs={ 12 } wrap="nowrap">
            <Grid item style={ { width: "100%" } }>
              <SearchField
                name={ "voting" }
                getSearch={ getSearch }
              // onSearch={onSearch}
              />
            </Grid>


          </Grid>

          <Grid container item xs={ 12 }>
            <SearchInfo paginationData={ paginationData } isLoading={ !isLoaded } />
          </Grid>

          <Grid container item xs={ 12 } style={ { marginBottom: 50 } }>
            <div style={ { width: "100%" } }>
              <InfiniteScroll
                dataLength={ bulletins.length }
                next={ fetchMoreData }
                hasMore={
                  bulletins.length < paginationData.totalCount ? true : false
                }
                loader={
                  <Grid
                    container
                    item
                    xs={ 12 }
                    justify="center"
                    style={ { marginTop: 50 } }>
                    <CircularSpinner forFetch={ true } />
                  </Grid>
                }
                // height={"100%"}
                scrollThreshold="300px"
                className={ classes.infScroll }
                endMessage={
                  bulletins.length === paginationData.totalCount && (
                    <p style={ { textAlign: "center" } }>
                      {/* <b> ви переглянули всі події! </b> */ }
                    </p>
                  )
                }>
                { bulletins.map((item: any) => (
                  <Grid key={ item.hashId } container item xs={ 12 }>
                    { item.hashId ? (
                      <ArchiveBulletinItem bulletin={ item } />
                    ) : (
                      <Grid
                        container
                        item
                        xs={ 12 }
                        justify="center"
                        style={ { marginTop: 50 } }>
                        <CircularSpinner forFetch={ true } />
                      </Grid>
                    ) }
                  </Grid>
                )) }
              </InfiniteScroll>
            </div>
          </Grid>

          <ToTopBtn
            paginationData={ paginationData }
            arrLength={ bulletins.length }
          />
        </Grid>
      </Grid>
    </>
  );
}

export default withSnackbar(ArchivePage);
