import { RcmdResponse, RcmdResponseBody } from "../models/rcmd-response.model";
import { ResponseValidationError } from "../models/error.model";
import Logger from "../utils/logger";
import { Role } from "../models/static.model";

class ResponseValidator {
  private logger = new Logger(this.constructor.name);

  public parseRole(role: string): Role {
    if (role === Role.Admin) {
      return Role.Admin;
    } else if (role === Role.User) {
      return Role.User;
    } else if (role === Role.Assistant) {
      return Role.Assistant;
    } else {
      return Role.Unknown;
    }
  }

  public validate<T extends RcmdResponseBody>(
    response: any,
    errorResp?: boolean
  ): RcmdResponse<T> {
    const isErrorResp = errorResp || false;
    if (typeof response != "object") {
      throw new ResponseValidationError(
        "resp validation: root object required"
      );
    }
    if (!response.hasOwnProperty("header")) {
      throw new ResponseValidationError(
        "resp validation: header field required"
      );
    }
    if (typeof response["header"] != "object") {
      throw new ResponseValidationError(
        "resp validation: header object required"
      );
    }
    if (!response["header"].hasOwnProperty("responseCode")) {
      throw new ResponseValidationError(
        "resp validation: header.responseCode field required"
      );
    }
    if (typeof response["header"]["responseCode"] != "number") {
      throw new ResponseValidationError(
        "resp validation: header.responseCode number required"
      );
    }
    if (isErrorResp && !response["header"].hasOwnProperty("errorMessage")) {
      throw new ResponseValidationError(
        "error resp validation: header.errorMessage field required"
      );
    }

    const validatedResponse: RcmdResponse<T> = response as RcmdResponse<T>;
    this.logger.debug("validated response:", validatedResponse);
    return validatedResponse;
  }

  public validateP<T extends RcmdResponseBody>(
    response: any,
    errorResp?: boolean
  ): Promise<RcmdResponse<T>> {
    try {
      const validatedResponse: RcmdResponse<T> = this.validate(
        response,
        errorResp
      );
      return Promise.resolve(validatedResponse);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export const responseValidator = new ResponseValidator();
