import React, { FunctionComponent, useMemo } from "react";
import {
  Route,
  Redirect,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";

import { authService } from "../../services/authService";
import { VOTING_PATH, SEARCH_PATH, SETTINGS_PATH } from "./var_PATHS";
import { dataService } from "../../services/dataService";
import { Role } from "../../models/static.model";
// import { Role } from "../../models/static.model";

interface PublicRouteProps extends RouteProps {
  restricted: boolean;
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const PublicRoute: FunctionComponent<PublicRouteProps> = (
  props: PublicRouteProps
) => {
  const { restricted, component: Component, render: Render, ...rest } = props;

  const role = useMemo(() => dataService.getRoleSync(), []);
  const isAdmin = role === Role.Admin;
  const isUser = role === Role.User;
  const isAssistant = role === Role.Assistant;

  const isLogedIn = useMemo(() => authService.isLoggedInSync(), []);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogedIn && restricted ? (
          <Redirect
            to={{
              pathname: isAdmin || isAssistant
                ? VOTING_PATH
                : isUser
                ? VOTING_PATH
                : SETTINGS_PATH,
              state: { from: props.location },
            }}
          />
        ) : Render ? (
          Render(props)
        ) : (
          Component && <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
