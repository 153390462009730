import Logger from "../utils/logger";
import { http } from "./httpService";
import { PagedResult } from "../models/profile.models";
import { config } from "../utils/config";
import {
  Bulletin,
  BulletinFilter,
  CancelBulletinRequest,
  NewBulletinRequest,
  UpdateBulletinEndDateRequest,
  UpdateBulletinInfoRequest,
  UpdateBulletinNoteRequest,
  UpdateBulletinProtocolDateRequest,
  UpdateBulletinProtocolNumberRequest,
  UpdateBulletinStartDateRequest,
} from "../models/bulletin.model";
import { NpuError } from "../models/error.model";
import history from "../utils/history";
import {  linkToArchive, linkToVoting } from "../components/Routes/var_PATHS";

export class BulletinService {
  private logger = new Logger(this.constructor.name);

  createBulletin(newBulletinRequest: NewBulletinRequest): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/new`;
    this.logger.debug("create bulletin request:", newBulletinRequest);
    return http
      .post<Bulletin>(path, newBulletinRequest)
      .then((resp: Bulletin) => {
        this.logger.debug("create bulletin response:", resp);
        return resp;
      });
  }

  editBulletinInfo(
    editBulletinRequest: UpdateBulletinInfoRequest
  ): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/info`;
    this.logger.debug("edit bulletin info request:", editBulletinRequest);
    return http
      .put<Bulletin>(path, editBulletinRequest)
      .then((resp: Bulletin) => {
        this.logger.debug("edit bulletin info response:", resp);
        return resp;
      });
  }

  updateBulletinProtocolNumber(
    updateBulletinRequest: UpdateBulletinProtocolNumberRequest
  ): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/protocol/number`;
    this.logger.debug(
      "edit bulletin protocol-number request:",
      updateBulletinRequest
    );
    return http
      .put<Bulletin>(path, updateBulletinRequest)
      .then((resp: Bulletin) => {
        this.logger.debug("edit bulletin protocol-number response:", resp);
        return resp;
      });
  }

  updateBulletinProtocolDate(
    updateBulletinRequest: UpdateBulletinProtocolDateRequest
  ): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/protocol/date`;
    this.logger.debug(
      "edit bulletin protocol-date request:",
      updateBulletinRequest
    );
    return http
      .put<Bulletin>(path, updateBulletinRequest)
      .then((resp: Bulletin) => {
        this.logger.debug("edit bulletin protocol-date response:", resp);
        return resp;
      });
  }

  updateBulletinStartlDate(
    updateBulletinRequest: UpdateBulletinStartDateRequest
  ): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/start/date`;
    this.logger.debug(
      "edit bulletin start-date request:",
      updateBulletinRequest
    );
    return http
      .put<Bulletin>(path, updateBulletinRequest)
      .then((resp: Bulletin) => {
        this.logger.debug("edit bulletin start-date response:", resp);
        return resp;
      });
  }

  updateBulletinEndDate(
    updateBulletinRequest: UpdateBulletinEndDateRequest
  ): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/end/date`;
    this.logger.debug("edit bulletin end-date request:", updateBulletinRequest);
    return http
      .put<Bulletin>(path, updateBulletinRequest)
      .then((resp: Bulletin) => {
        this.logger.debug("edit bulletin end-date response:", resp);
        return resp;
      });
  }

  updateBulletinNote(
    updateBulletinRequest: UpdateBulletinNoteRequest
  ): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/note`;
    this.logger.debug("edit bulletin note request:", updateBulletinRequest);
    return http
      .put<Bulletin>(path, updateBulletinRequest)
      .then((resp: Bulletin) => {
        this.logger.debug("edit bulletin note response:", resp);
        return resp;
      });
  }

  //btn cancel
  cancelBulletin(
    cancelBulletinRequest: CancelBulletinRequest
  ): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/cancel`;
    this.logger.debug("cancel bulletin request:", cancelBulletinRequest);
    return http
      .put<Bulletin>(path, cancelBulletinRequest)
      .then((resp: Bulletin) => {
        this.logger.debug("cancel bulletin note response:", resp);
        return resp;
      });
  }

  searchBulletin(filter: BulletinFilter): Promise<PagedResult<Bulletin>> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/search`;
    this.logger.debug("search bulletin request:", filter);
    return http
      .post<PagedResult<Bulletin>>(path, filter)
      .then((resp: PagedResult<Bulletin>) => {
        this.logger.debug("search bulletin response:", resp);
        return resp;
      });
  }

  searchBulletinArchive(filter: BulletinFilter): Promise<PagedResult<Bulletin>> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/search/archive`;
    this.logger.debug("search bulletin-archive request:", filter);
    return http
      .post<PagedResult<Bulletin>>(path, filter)
      .then((resp: PagedResult<Bulletin>) => {
        this.logger.debug("search bulletin-archive response:", resp);
        return resp;
      });
  }

//link
  getBulletinByHashId(bulletinHashId: string): Promise<Bulletin | void> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}`;
    this.logger.debug("get bulletin by hash-id");
    return http.get<Bulletin>(path).then((resp: Bulletin) => {
      this.logger.debug("get bulletin by hash-id response:", resp);
      return resp;
    }).catch((err: NpuError) => {
      if (err.errorCode === 10) {
        linkToVoting()
      }
      if (err.errorCode === 382) {
        linkToVoting()
      }

    });
  }

  getBulletinArchiveByHashId(bulletinHashId: string): Promise<Bulletin | void> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}/archive`;
    this.logger.debug("get bulletin-archive by hash-id");
    return http.get<Bulletin>(path).then((resp: Bulletin) => {
      this.logger.debug("get bulletin-archive by hash-id response:", resp);
      return resp;
    }).catch((err: NpuError) => {
      if (err.errorCode === 10) {
        linkToArchive()
      }
      if (err.errorCode === 392) {
        linkToArchive()
      }

    });
  }


  moveBulletinToArchive(bulletinHashId: string): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}/archive/move`;
    this.logger.debug("move bulletin to archive by hash-id");
    return http.get<Bulletin>(path).then((resp: Bulletin) => {
      this.logger.debug("move bulletin toarchive by hash-id response:", resp);
      return resp;
    });
  }

//btn draft->publish
  publishBulletin(bulletinHashId: string): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}/publish`;
    this.logger.debug("publish bulletin by hash-id");
    return http.get<Bulletin>(path).then((resp: Bulletin) => {
      this.logger.debug("publish bulletin by hash-id response:", resp);
      return resp;
    });
  }

//btn publish->draft
  hideBulletin(bulletinHashId: string): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}/hide`;
    this.logger.debug("hide bulletin by hash-id");
    return http.get<Bulletin>(path).then((resp: Bulletin) => {
      this.logger.debug("hide bulletin by hash-id response:", resp);
      return resp;
    });
  }

//btn publish->pending
  startBulletin(bulletinHashId: string): Promise<Bulletin> {
    const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}/start`;
    this.logger.debug("start bulletin by hash-id");
    return http.get<Bulletin>(path).then((resp: Bulletin) => {
      this.logger.debug("start bulletin by hash-id response:", resp);
      return resp;
    });
  }
}

export const bulletinService = new BulletinService();