import React, { useMemo } from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Typography, Toolbar, useMediaQuery } from "@material-ui/core/";

import { dataService } from "../../services/dataService";


import { linkToVoting } from "../Routes/var_PATHS";
import HeaderMenuBtn from "./HeaderMenuBtn";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
      fontWeight: 500,
    },
    headerLogo: {
      fontSize: 26,
      transition: "0.2s",
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        textShadow: `0px 0px 3px ${ theme.palette.primary.main }`,
      },
    },

    header: {
      // marginBottom: 40,
      // top: 10
      minWidth: 375,
      width: "100%",
      top: 0,
      position: "fixed",
      backgroundColor: theme.palette.background.default,
      zIndex: 1000,
      height: 70,
    },
    btnStyles: {
      height: 45,
      border: `2px solid ${ theme.palette.primary.main }`,
      boxSizing: "border-box",
      boxShadow: `0px 0px 4px ${ theme.palette.primary.main }`,
      borderRadius: 25,
      transition: "0.2s ease-in-out",
      "&:hover": {
        boxShadow: `0px 0px 10px ${ theme.palette.primary.main }`,
      },
    },
  })
);

const HeaderAppBar = (props: any) => {
  const classes = useStyles();

  const onClickLogo = (e: any) => {
    e.preventDefault();
    linkToVoting();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const matches430 = useMediaQuery("(max-width:430px)");


  return (
    <div className={ classes.header }>
      <Toolbar>
        <Typography className={ classes.title } component="h4">
          <Button
            onClick={ (e: any) => onClickLogo(e) }
            disableRipple
            disableFocusRipple
            className={ classes.headerLogo }
          >
            {/* <img src="/img/rcmdLogo.svg" alt="logo" style={{ width: 37 }} /> */ }

            { matches430 ?
              (<Typography variant="h6">
                ВНПУ
                <span
                  style={ {
                    position: "relative",
                    fontSize: 28,
                    // top: 2,
                    fontWeight: 200,
                    padding: "0px 5px",
                  } }
                >
                  |
              </span>
              Голосування
              </Typography>)
              :
              (<Typography variant="h4" component="h4">
                ВНПУ
                <span
                  style={ {
                  position: "relative",
                  fontSize: 45,
                  // top: 2,
                  fontWeight: 200,
                  padding: "0px 5px",
                  } }
              >
                |
              </span>
              Голосування
              </Typography>)
            }
          </Button>
        </Typography>

        {
          <>
            <HeaderMenuBtn
              email={ props.email }
              firstName={ props.firstName }
              lastName={ props.lastName }
              pictureUrl={ props.pictureUrl }
              authProvider={ props.authProvider }
              role={ props.role }
            />
          </>
        }
      </Toolbar>
    </div>
  );
};

export default HeaderAppBar;
