import React, { useMemo } from "react";
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Grow from "@material-ui/core/Grow/Grow";
import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar, useSnackbar } from "notistack";
import { CancelBulletinRequest, BulletinStatus } from "../../models/bulletin.model";
import { bulletinService } from "../../services/bulletinService";
import PublishBtn from "../ButtonsWithConfirmation/PublishBtn";
import HideBtn from "../ButtonsWithConfirmation/HideBtn";
import StartBtn from "../ButtonsWithConfirmation/StartBtn";
import MakeVoteBtn from '../ButtonsWithConfirmation/MakeVoteBtn';
import RejectBulletinBtnWithReason from "../ButtonsWithConfirmation/RejectBulletinBtnWithReason";
import RejectBulletinBtn from "../ButtonsWithConfirmation/RejectBulletinBtn";
import { NpuError } from "../../models/error.model";
import { votingService } from "../../services/votingService";
import ArchiveBtn from "../ButtonsWithConfirmation/ArchiveBtn";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.background.paper,
    borderRadius: 5,
    width: "100%",
    margin: "30px auto ",
    padding: "15px"
    // cursor: "pointer",
  },

  btnStylesWithOutline: {
    minHeight: 48,
    border: `2px solid ${ theme.palette.primary.main }`,
    backgroundColor: theme.palette.background.paper,

    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${ theme.palette.primary.main }`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${ theme.palette.primary.main }`
    }
  }
}));

const BtnBlock = (props: any) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const cancelAction = () => {
    return (
      <IconButton onClick={ () => closeSnackbar() }>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const myRole = useMemo(() => dataService.getRoleSync(), []);
  const isAdmin = myRole === Role.Admin;
  const isAssist = myRole === Role.Assistant;
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleRejectRequest = (req: CancelBulletinRequest) => {
    try {
      // const reason = ''
      bulletinService.cancelBulletin(req).then(() => props.getUpdateVoting()).catch((e: NpuError) =>
        enqueueSnackbar(e.message, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          },
          variant: "error",
          action: cancelAction
        })
      );
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        variant: "error",
        action: cancelAction
      });
    }
  };

  const handlePublishBulletin = (id: any) => {
    try {
      // const reason = ''
      bulletinService.publishBulletin(id).then(() => props.getUpdateVoting()).catch((e: NpuError) =>
        enqueueSnackbar(e.message, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          },
          variant: "error",
          action: cancelAction
        })
      );
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        variant: "error",
        action: cancelAction
      });
    }
  };

  const handleHideBulletin = (id: any) => {
    try {
      // const reason = ''
      bulletinService.hideBulletin(id).then(() => props.getUpdateVoting()).catch((e: NpuError) =>
        enqueueSnackbar(e.message, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          },
          variant: "error",
          action: cancelAction
        })
      );
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        variant: "error",
        action: cancelAction
      });
    }
  };

  const handleStartBulletin = (id: any) => {
    try {
      // const reason = ''
      bulletinService.startBulletin(id).then(() => props.getUpdateVoting()).catch((e: NpuError) =>
        enqueueSnackbar(e.message, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          },
          variant: "error",
          action: cancelAction
        })
      );
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        variant: "error",
        action: cancelAction
      });
    }
  };

  const handleArchiveBulletin = (id: any) => {
    try {
      // const reason = ''
      bulletinService.moveBulletinToArchive(id).then(() => props.getUpdateVoting()).catch((e: NpuError) =>
        enqueueSnackbar(e.message, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          },
          variant: "error",
          action: cancelAction
        })
      );
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        variant: "error",
        action: cancelAction
      });
    }
  };


  const handleMakeVote = () => {

    const votingReq = {
      bulletinHashId: props.bulletin.hashId,
      votes: props.votReq
    }

    votingService.makeVote(votingReq).then(() => props.getUpdateVoting()).catch((e: NpuError) =>
      enqueueSnackbar(e.message, {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        variant: "error",
        action: cancelAction
      })
    );

  };

  const handleWithOptions = () => 'withVotingOptionScore' in props.bulletin.status


  return (

    <Grow in>
      <Grid
        container
        item
        xs={ 12 }
        className={ classes.card }
        justify="space-between"
      >


        {props.bulletin.isVotedByMe && props.bulletin.status.id === BulletinStatus.Completed.valueOf() ?
          <Grid container item xs={12} justify='center'>
            <Typography variant='h6' color='primary' > Результат сформовано автоматично.</Typography>
            <Box width="100%" />
            <Typography variant='h6' color='primary' > Підрахунок виконується програмою.</Typography>
          </Grid> : <></>
        }


        {props.bulletin.isVotedByMe && props.bulletin.status.id !== BulletinStatus.Completed.valueOf() ?
          <Grid container item xs={ 12 } justify='center'>
            <Typography variant='h6' color='primary' > Ви вже проголосували!</Typography>
          </Grid> :
          <>
            {/* ADMIN's BLOCK */ }
            { (isAdmin || isAssist) && <>

              { props.bulletin.status.id === 0 ? (
                <Grid item>
                  <RejectBulletinBtn
                    handleReject={ handleRejectRequest }
                    hashId={ props.bulletin.hashId }
                    name={ props.bulletin.status.id === 0 ? 'Видалити' : "Скасувати" }
                  />
                </Grid>
              ) : (
                  props.bulletin.status.id <= 3 && <Grid item>
                  <RejectBulletinBtnWithReason
                    handleReject={ handleRejectRequest }
                    hashId={ props.bulletin.hashId }
                  />
                </Grid>
              ) }

              { props.bulletin.status.id === 0 && (
                <Grid item>
                  <PublishBtn
                    publishBulletin={ handlePublishBulletin }
                    hashId={ props.bulletin.hashId }
                  />
                </Grid>
              ) }

              { props.bulletin.status.id === 1 && (
                <Grid item>
                  <HideBtn
                    hideBulletin={ handleHideBulletin }
                    hashId={ props.bulletin.hashId }
                  />
                </Grid>
              ) }

              { props.bulletin.status.id === 1 && (
                <Grid item>
                  <StartBtn
                    startBulletin={ handleStartBulletin }
                    hashId={ props.bulletin.hashId }
                  />
                </Grid>
              )
              }

              { props.bulletin.status.id === 8 || props.bulletin.status.id === 7 && (
                <Grid item>
                  <ArchiveBtn
                    archiveBulletin={ handleArchiveBulletin }
                    hashId={ props.bulletin.hashId }
                  />
                </Grid>
              )
              }
            </> }

            {/* USER`s BLOCK */ }



            {
              <>
                { isAdmin || isAssist ? (props.bulletin.status.id === 3 && handleWithOptions() && (
                  <></>
                  // props.bulletin.isVotedByMe ? 'Ви вже проголосували!!!' :
                  //   <MakeVoteBtn
                  //     isDisabledVoting={ props.currentThreshold === props.votReq.map((item: any) => item.optionHashIds.length).reduce((a: any, b: any) => a + b, 0) }
                  //       makeVote={ handleMakeVote }
                  //       hashId={ props.bulletin.hashId }
                  //     />

                )) : (props.bulletin.status.id === 3 && (
                  <Grid container item xs={ 12 } justify='center'>
                    { props.bulletin.isVotedByMe ? 'Ви вже проголосували!!!' :
                        <MakeVoteBtn
                          isDisabledVoting={ props.currentThreshold === props.votReq.map((item: any) => item.optionHashIds.length).reduce((a: any, b: any) => a + b, 0) }
                        makeVote={ handleMakeVote }
                        hashId={ props.bulletin.hashId }
                      />
                    }
                  </Grid>
                )) }

                { props.bulletin.status.id === 1 || props.bulletin.status.id === 2 &&

                  <Grid container item xs={ 12 } justify='center'>
                    <Typography color='primary'>
                      Голосування ще не розпочалося.
                    </Typography>
                  </Grid> }

              </>



            }

          </>
        }
      </Grid>
    </Grow>

  );
};

export default withSnackbar(BtnBlock);
