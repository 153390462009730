import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import React from 'react';
import { AuthToken } from '../../models/token.model';
import { dataService } from '../../services/dataService';
import { config } from '../../utils/config';

interface IProps {
  isArchive: boolean;
  bulletinHashId: string;
  votingHashId: string;
}

const LightTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 15,
  },
}))(Tooltip);


const VotingAuthlogReportPrint = (props: IProps) => {

  const handlePrintAction = (bulletinHashId: string, votingHashId: string, isArchive: Boolean) => {
    dataService.getSessionToken().then((data: AuthToken) => {
      if (isArchive) {
        const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}/archive/voting/${votingHashId}/report/authlog/view?authToken=${data.token}`;
        return window.open(path, '_blank')
      } else {
        const path = `${config.beBaseUrl}/api/v1/bulletin/${bulletinHashId}/voting/${votingHashId}/report/authlog/view?authToken=${data.token}`;
        return window.open(path, '_blank')
      }
    }
    )
  }

  return (
    <LightTooltip
      placement='left'
      enterDelay={200}
      leaveDelay={200}
      title="Роздрукувати Журнал Аутентифікації">
      <IconButton onClick={() => handlePrintAction(props.bulletinHashId, props.votingHashId, props.isArchive)}>
        <PrintIcon />
      </IconButton>
    </LightTooltip>
  )

}

export default VotingAuthlogReportPrint