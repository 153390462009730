import React, { useEffect, useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withSnackbar, useSnackbar } from "notistack";
import { cyan, amber } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import {
  ThemeProvider,
  responsiveFontSizes,
  createMuiTheme,
} from "@material-ui/core/styles";
import {
  makeStyles,
  Theme,
  CssBaseline,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import {
  ERROR_PATH,
  AUTH_PATH,
  LOGIN_PATH,
  linkToLogin,
  MAINTENANCE_PATH,
  LICENSES_PATH,
  USERS_PATH,
  SETTINGS_PATH,
  UNAUTHORIZED_PATH,
  REQUESTS_PATH,
  VOTING_PATH,
  ARCHIVE_PATH
} from "./components/Routes/var_PATHS";
import { pubSubService } from "./services/pubSubService";
import { authService } from "./services/authService";
import { dataService } from "./services/dataService";
import { wsService } from "./services/wsService";
import { Role } from "./models/static.model";
import AuthComponent from "./components/AuthComponent/AuthComponent";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import PrivateRoute from "./components/Routes/PrivateRoute";
import PublicRoute from "./components/Routes/PublicRoute";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import Header from "./components/Header/Header";
import Login from "./components/Login/Login";
import Maintenance from "./components/Maintenance/Maintenance";
import BulletinPage from './components/BulletinPage/BulletinPage';
import LicensesPage from "./components/Licenses/LicensesPage";
import UsersPage from "./components/UsersPage/UsersPage";
import AccountPage from "./components/Account/AccountPage";
import UnauthorizedPage from "./components/Unauthorized/UnauthorizedPage";
import { LogOutUserEvent } from "./models/event.model";
import RequestsPage from "./components/Requests/RequestsPage";
import VotingPage from "./components/VotingPage/VotingPage";
import ArchivePage from "./components/ArchivePage/ArchivePage";
import ArchiveVotingPage from "./components/VotingPage/ArchiveVotingPage";
import VotingResultReport from "./components/VotingPage/VotingResultReport";
import VotingAttendanceReport from "./components/VotingPage/VotingAttendanceReport";
import VotingAuthlogReport from "./components/VotingPage/VotingAuthlogReport";

export const LIGHT = "light";
export const DARK = "dark";
export const AUTO = "auto";
export const THEME_TYPE = "mod";

const useStyles = makeStyles((theme: Theme) => ({
  mainApp: {
    minWidth: 375,
    margin: "0 auto ",
  },
}));

const App: React.FC = () => {
  const classes = useStyles();
  const matches890 = useMediaQuery("(min-width:890px)");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton
        disableRipple
        disableFocusRipple
        onClick={() => closeSnackbar()}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  const myRole = dataService.getRoleSync();

  const isLoggedIn = useMemo(() => authService.isLoggedInSync(), []);

  let theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: DARK,
          primary: cyan,
          secondary: amber,
          contrastThreshold: 3,
          text: {
            primary: "#fefefe",
            secondary: "#cfd8dc",
            disabled: "red",
            hint: "yellow",
          },
          background: {
            paper: "#424242",
            default: "#212121",
          },
          action: {
            hoverOpacity: 0.08,
            hover: "#6ec6ff50",
            selected: "#1AD0D035",
            disabledBackground: "#212121",
            disabled: "#616161",
            active: "#cfd8dc",
          },
        },
        typography: {
          fontFamily: ["Montserrat", "sans-serif"].join(","),
        },
      }),
    []
  );
  theme = responsiveFontSizes(theme);

  const handleLogout = () => {
    try {
      authService.logout().then(() => linkToLogin());
    } catch (err) {
      enqueueSnackbar(err.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "error",
        action: cancelAction,
      });
    }
  };

  const eventHandler = function (msg: any, data: any) {
    if (data instanceof LogOutUserEvent) {
      handleLogout();
    }
  };

  useEffect(() => {
    try {
      pubSubService.authSubscribe(eventHandler);
      wsService.connect();
    } catch (error) {
      enqueueSnackbar(error.toString(), {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "error",
        action: cancelAction,
      });
    }

    return () => {
      pubSubService.unsubsribe(eventHandler, "auth-handler");
    };
    // eslint-disable-next-line
  }, [myRole]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          <PublicRoute
            path={AUTH_PATH}
            exact
            restricted={true}
            render={(hisroty) => (
              <AuthComponent location={hisroty.location.search} />
            )}
          />

          <PublicRoute
            path={ERROR_PATH}
            exact
            restricted={false}
            component={ErrorPage}
          />

          <PublicRoute
            restricted={true}
            path={LOGIN_PATH}
            exact
            component={Login}
          />

          <PublicRoute
            restricted={false}
            path={MAINTENANCE_PATH}
            exact
            component={Maintenance}
          />

          {isLoggedIn ? (
            <>
              <div style={{ marginBottom: matches890 ? 30 : 40 }}>
                <Route component={Header}></Route>
              </div>
              <main
                className={classes.mainApp}
                style={{
                  width: "95%",
                }}
              >
                {myRole === Role.Admin && (
                  <Switch>

                    <PrivateRoute
                      path={LICENSES_PATH}
                      exact
                      component={LicensesPage}
                    />

                    <PrivateRoute
                      path={USERS_PATH}
                      exact
                      component={UsersPage}
                    />

                    <PrivateRoute
                      path={VOTING_PATH}
                      exact
                      component={BulletinPage}
                    />

                    <PrivateRoute
                      path={`${VOTING_PATH}/:id`}
                      exact
                      component={VotingPage}
                    />

                    <PrivateRoute
                      path={`${VOTING_PATH}/:bId/voting/:vId`}
                      exact
                      component={VotingResultReport}
                    />

                    <PrivateRoute
                      path={`${VOTING_PATH}/:bId/voting/:vId/attendance`}
                      exact
                      component={VotingAttendanceReport}
                    />

                    <PrivateRoute
                      path={`${VOTING_PATH}/:bId/voting/:vId/authlog`}
                      exact
                      component={VotingAuthlogReport}
                    />

                    <PrivateRoute
                      path={ARCHIVE_PATH}
                      exact
                      component={ArchivePage}
                    />

                    <PrivateRoute
                      path={`${ARCHIVE_PATH}/:id`}
                      exact
                      component={ArchiveVotingPage}
                    />

                    <PrivateRoute
                      path={`${ARCHIVE_PATH}/:bId/voting/:vId`}
                      exact
                      component={VotingResultReport}
                    />

                    <PrivateRoute
                      path={`${ARCHIVE_PATH}/:bId/voting/:vId/attendance`}
                      exact
                      component={VotingAttendanceReport}
                    />

                    <PrivateRoute
                      path={`${ARCHIVE_PATH}/:bId/voting/:vId/authlog`}
                      exact
                      component={VotingAuthlogReport}
                    />

                    <PrivateRoute
                      path={REQUESTS_PATH}
                      exact
                      component={RequestsPage}
                    />

                    <PrivateRoute
                      path={UNAUTHORIZED_PATH}
                      exact
                      component={UnauthorizedPage}
                    />

                    <PrivateRoute
                      path={SETTINGS_PATH}
                      exact
                      component={AccountPage}
                    />

                    <PublicRoute
                      path="*"
                      restricted={false}
                      component={PageNotFound}
                    />
                  </Switch>
                )}

                {myRole === Role.User && (
                  <Switch>

                    <PrivateRoute
                      path={VOTING_PATH}
                      exact
                      component={BulletinPage}
                    />

                    <PrivateRoute
                      path={`${VOTING_PATH}/:id`}
                      exact
                      component={VotingPage}
                    />

                    <PrivateRoute
                      path={`${VOTING_PATH}/:bId/voting/:vId`}
                      exact
                      component={VotingResultReport}
                    />

                    <PrivateRoute
                      path={SETTINGS_PATH}
                      exact
                      component={AccountPage}
                    />

                    <PrivateRoute
                      path={ARCHIVE_PATH}
                      exact
                      component={ArchivePage}
                    />

                    <PrivateRoute
                      path={`${ARCHIVE_PATH}/:id`}
                      exact
                      component={ArchiveVotingPage}
                    />

                    <PrivateRoute
                      path={`${ARCHIVE_PATH}/:bId/voting/:vId`}
                      exact
                      component={VotingResultReport}
                    />

                    <PublicRoute
                      path="*"
                      restricted={false}
                      component={PageNotFound}
                    />
                  </Switch>
                )}

                {myRole === Role.Assistant && (
                  <Switch>
                    <PrivateRoute
                      path={VOTING_PATH}
                      exact
                      component={BulletinPage}
                    />

                    <PrivateRoute
                      path={`${VOTING_PATH}/:id`}
                      exact
                      component={VotingPage}
                    />

                    <PrivateRoute
                      path={ARCHIVE_PATH}
                      exact
                      component={ArchivePage}
                    />

                    <PrivateRoute
                      path={`${ARCHIVE_PATH}/:id`}
                      exact
                      component={ArchiveVotingPage}
                    />

                    <PrivateRoute
                      path={REQUESTS_PATH}
                      exact
                      component={RequestsPage}
                    />

                    <PrivateRoute
                      path={SETTINGS_PATH}
                      exact
                      component={AccountPage}
                    />

                    <PublicRoute
                      path="*"
                      restricted={false}
                      component={PageNotFound}
                    />
                  </Switch>
                )}
                {(myRole === Role.Empty || myRole === Role.Unknown) &&
                  <Switch>
                    <PublicRoute
                      path="*"
                      restricted={false}
                      component={PageNotFound}
                    />
                  </Switch>
                }

              </main>
            </>
          ) : (
            <Redirect to={LOGIN_PATH} />
          )}
        </Switch>
      </ThemeProvider>
    </div>
  );
};

export default withSnackbar(App);
