import { Bulletin } from "./bulletin.model";
import { RcmdRequest } from "./rcmd-request.model";

export class VotingUrlRequest implements RcmdRequest {
  name?: string;
  url: string;
  id: number;

  constructor(url: string, id: number, name?: string,) {
    this.name = name;
    this.url = url;
    this.id = id;
  }
}

export interface VotingUrl {
  hashId?: string;
  votingHashId?: string;
  name?: string;
  url?: string;
}

export class VotingOptionRequest implements RcmdRequest {
  name: string;
  id: number;
  constructor(name: string, id: number) {
    this.name = name;
    this.id = id;
  }
}

export interface VotingOption {
  id?: number;
  hashId?: string;
  name?: string;
  optionScore?: number;
  percentage?: number;
  isWinner?: boolean;
  modifiedAt?: number;
  createdAt?: number;
  position?: number;
  optionNumber?: number;
}

export class AddVotingRequest implements RcmdRequest {
  subject: string;
  body: string;
  threshold: number;
  options: Array<VotingOptionRequest>;
  bulletinHashId?: string;
  note?: string;
  urls?: Array<VotingUrlRequest>;

  constructor(
    subject: string,
    body: string,
    threshold: number,
    options: Array<VotingOptionRequest>,
    bulletinHashId?: string,
    note?: string,
    urls?: Array<VotingUrlRequest>
  ) {
    this.bulletinHashId = bulletinHashId;
    this.subject = subject;
    this.body = body;
    this.threshold = threshold;
    this.note = note;
    this.urls = urls;
    this.options = options;
  }
}

export class DeleteVotingRequest implements RcmdRequest {
  bulletinHashId: string;
  votingHashId: string;

  constructor(bulletinHashId: string, votingHashId: string) {
    this.bulletinHashId = bulletinHashId;
    this.votingHashId = votingHashId;
  }
}

export class UpdateVotingRequest implements RcmdRequest {
  bulletinHashId: string;
  votingHashId: string;
  subject: string;
  body: string;
  threshold: number;
  note?: string;
  urls?: Array<VotingUrlRequest>;
  options?: Array<VotingOptionRequest>;

  constructor(
    bulletinHashId: string,
    votingHashId: string,
    subject: string,
    body: string,
    threshold: number,
    note?: string,
    urls?: Array<VotingUrlRequest>,
    options?: Array<VotingOptionRequest>
  ) {
    this.bulletinHashId = bulletinHashId;
    this.votingHashId = votingHashId;
    this.subject = subject;
    this.body = body;
    this.threshold = threshold;
    this.note = note;
    this.urls = urls;
    this.options = options;
  }
}

export interface Voting {
  id?: number;
  hashId?: string;
  votingType?: VotingTypeView,
  isVotedByMe?: boolean;
  threshold?: number;
  subject?: string;
  body?: string;
  note?: string;
  votedUsers?: number;
  urls?: Array<VotingUrl>;
  options?: Array<VotingOption>;
  result?: VotingResult;
  votedItems?: VotedVotingVotes;
  createdAt?: number;
  modifiedAt?: number;
  personalVotedBulletinId?: string;
}

export enum RegisteredNotariesSourceType {
  Registry = 0,
  Manual = 1,
}

export interface RegisteredNotariesSourceTypeView {
  id: number,
  name: string
}

export enum BulletinQuorumType {
  Registry = 0,
  Manual = 1,
}

export interface BulletinQuorumTypeView {
  id: number,
  name: string
}

export interface VotingResult {
  isDecisionDone?: Boolean;
  winnersExceededThreshold?: Boolean;
  votedUsers?: number;
  registeredUsers?: number;
  scoredOptions: Array<VotingOption>;
  quorumType?: BulletinQuorumTypeView,
  quorum?: number;
  registeredNotariesSourceType?: RegisteredNotariesSourceTypeView;
  registeredNotaries?: number;
  registeredNotariesSource?: string;
}

export class BulletinVoteRequest implements RcmdRequest {
  bulletinHashId: string;
  votes: Array<VotingVoteRequest>;

  constructor(bulletinHashId: string, votes: Array<VotingVoteRequest>) {
    this.bulletinHashId = bulletinHashId;
    this.votes = votes;
  }
}

export class VotingVoteRequest implements RcmdRequest {
  votingHashId: string;
  optionHashIds: Array<string>;

  constructor(votingHashId: string, optionHashIds: Array<string>) {
    this.votingHashId = votingHashId;
    this.optionHashIds = optionHashIds;
  }
}

export interface VotedVotingVotes {
  votingHashId?: string;
  votedOptions?: Array<VotingOption>;
  votedAt?: number;
  votedOptionsWithVoteHashId?: Array<VotingOptionWithVoteHashId>;
}

export interface VotingOptionWithVoteHashId {
  votingVoteHashId?: string;
  votedOptions?: VotingOption;
}

export interface VotingRegistry {
  bulletin: Bulletin;
  votes: Array<VotingRegistryVote>;
}

export interface VotingAttendance {
  bulletin: Bulletin;
  attendance: Array<VotingRegistryVote>;
}

export interface VotingAuthlog {
  bulletin: Bulletin;
  authlog: Array<VotingAuthlogItem>;
}

export interface VotingAuthlogItem {
  licence: number;
  fio: string;
  authAt: string
}

export interface VotingRegistryVote {
  votedAt: string;
  personalVotedBulletinId: string;
  licence?: number;
  fio?: string;
  votesView: string;
}

export enum VotingType {
  Voting = 0,
  Poll = 1,
}

export interface VotingTypeView {
  id: number;
  name: string;
}