import React, { useEffect, useState, useMemo } from "react";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  Button,
  useTheme,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import EditIcon from '@material-ui/icons/Edit';
import Grow from "@material-ui/core/Grow/Grow";
import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";
import { licenceService } from "../../services/licenceService";
import DeleteBtn from "../ButtonsWithConfirmation/DeleteBtn";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar, useSnackbar } from "notistack";
import CancelBtn from "../ButtonsWithConfirmation/CancelBtn";
import AcceptBtn from "../ButtonsWithConfirmation/AcceptBtn";
import EditBtn from "../ButtonsWithConfirmation/EditBtn";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InputAdornment from '@material-ui/core/InputAdornment';
import history from "../../utils/history";
import { VOTING_PATH } from "../Routes/var_PATHS";
import EditedBulletin from "./EditedBulletin";
import PrintBulletin from "./PrintBulletin";
import { BulletinStatus } from "../../models/bulletin.model";
import { BulletinQuorumType, RegisteredNotariesSourceType } from "../../models/voting.model";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: theme.palette.background.paper,
    // height: 300,
    width: "100%",
    // margin: "30px auto 0px",
    margin: "0 auto ",
    // cursor: "pointer",
  },

  btnStylesWithOutline: {
    minHeight: 48,
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,

    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
    },
  },
}));


const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    // color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[4],
    // borderRadius: 20,
    fontSize: 15,

  },
}))(Tooltip);

const LightTextInDisabledTextField = withStyles({
  root: {
    // marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#fefefe", // (default alpha is 0.38)
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      color: "#fefefe90", // (default alpha is 0.38)
    },
  },
})(TextField);

const BulletinVoting = (props: any) => {
  const { protocolNumber,
    protocolDate,
    startDate,
    endDate,
    note,
    hashId,
    createdBy,
    id,
    createdAt,
    modifiedAt,
    status,
    isVotedByMe,
    personalVotedBulletinID,
    stats
  } = props.bulletin;



  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cancelAction = () => {
    return (
      <IconButton onClick={() => closeSnackbar()}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };


  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const isAdmin = myRole === Role.Admin;
  const isAssist = myRole === Role.Assistant;
  const isUser = myRole === Role.User;


  const theme = useTheme();

  const classes = useStyles();
  const { t } = useTranslation();

  const [isEdited, setIsEdited] = useState(false);

  const handleEditBulletin = () => {
    setIsEdited(!isEdited);

  };

  const getUpdateBulletin = () => {
    props.updateBulletin()
    handleEditBulletin()
  }
  const matches980 = useMediaQuery("(max-width:980px)");
  const matches700 = useMediaQuery("(max-width:700px)");
  const matches530 = useMediaQuery("(max-width:530px)");


  const colorHandler = (id: any) => {
    if (id === 3) {
      return 'green'
    }
    if (id === 2 || id === 4) {
      return 'orange'
    }
    if (id === 8) {
      return 'red'
    }
    return ''

  }

  const isBulletinRoutePath: boolean = String(history.location.pathname.split("/").slice(-2).shift()) == "bulletins";

  const isArchiveRoutePath: boolean = String(history.location.pathname.split("/").slice(-2).shift()) == "archive";

  const goToBulletin = () => { history.push("/bulletins") };

  const goToArchive = () => { history.push("/archive") };

  const goBackToBulletins = () => {
    isBulletinRoutePath && goToBulletin();
    isArchiveRoutePath && goToArchive();
  }

  return (
    <Grow in>
      <>

        {isEdited && (isAdmin || isAssist) && <EditedBulletin getUpdateBulletin={getUpdateBulletin} bulletin={props.bulletin} />}



        <Grid container item xs={12} justify='space-between' style={{ marginTop: '10px' }}>
          <Grid>
            <LightTooltip
              placement='right'
              enterDelay={200}
              leaveDelay={200}
              title="Назад">
              <IconButton onClick={() => goBackToBulletins()}>
                <ArrowBackIosIcon />
              </IconButton>
            </LightTooltip>



          </Grid>

          <Grid>
            {props.bulletin.status.id <= 3 && (isAdmin || isAssist) && <LightTooltip
              placement='left'
              enterDelay={200}
              leaveDelay={200}
              title="Редагувати бюлетень">
              <IconButton onClick={handleEditBulletin}>
                <EditIcon />
              </IconButton>
            </LightTooltip>}

            {(props.bulletin.status.id === 7 || props.bulletin.status.id === 9) && (isAdmin || isAssist || isUser) && <PrintBulletin id={props.bulletin.hashId} isArchive={props.bulletin.status.id === 9} />}

          </Grid>
        </Grid>

        <Card className={classes.card} onClick={() => {

        }}>

          {matches530 && <Grid container item xs={12} style={{ position: 'relative' }} >
            <Chip
              style={{ position: 'absolute', top: 5, right: 5, backgroundColor: colorHandler(status.id) }}
              color='primary'
              // icon={<AssignmentIndIcon />}
              label={<span style={{ fontSize: 18 }}>{status.name}</span>}
            />

          </Grid>}

          <Grid
            container
            item
            xs={12}
            // onClick={() => handleOpenObject(props.user.hashId)}
            style={{ padding: "10px", display: "flex" }}
            justify="space-between"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
          >


            <Grid container item  >

              {matches700 ?
                <Grid>
                  <Typography variant='h6' >
                    {`Протокол № ${protocolNumber}`}

                  </Typography>
                  <Typography variant='h6' >
                    {`від ${new Date(
                      protocolDate
                    ).toLocaleString('de-DE', {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",

                    })} року`}

                  </Typography>
                </Grid>
                : <Grid container item
                  xs={12}
                  // direction={ matches980 ? 'column' : "row" }
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  wrap='nowrap'
                >

                  <Grid container direction='row' wrap='nowrap'
                    alignItems="center"
                    justify='flex-start'
                    style={{ width: 'max-content', whiteSpace: 'nowrap' }}
                  >

                    <Typography variant='h6' style={{ marginRight: 15 }}>
                      Протокол {matches980 && `№ ${protocolNumber}`}
                    </Typography>

                    {!matches980 && <LightTextInDisabledTextField
                      disabled
                      // label={t("referral.input")}
                      // label={t("licences.licenceN")}
                      // label="№"
                      variant="outlined"
                      style={{ width: 160, marginRight: 25 }}
                      value={protocolNumber}
                      inputProps={{
                        style: { textAlign: "center", fontSize: 24 },
                        disabled: true,
                        // textAlign: "center",
                      }}

                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            №
                          </InputAdornment>
                        ),
                      }}
                    />}
                  </Grid>
                  {matches980 ?
                    (<Grid container item direction='row' wrap='nowrap'
                      justify='flex-start'
                    >
                      <Typography variant='h6'>
                        {`від ${new Date(
                          protocolDate
                        ).toLocaleString('de-DE', {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",

                        })} року`}
                      </Typography>

                    </Grid>)
                    :
                    (<Grid container item direction='row' wrap='nowrap'
                      alignItems="center"
                    >
                      <Typography variant='h6' style={{ marginRight: 15 }}>
                        від
                      </Typography>
                      <LightTextInDisabledTextField
                        disabled
                        // label={t("referral.input")}
                        // label={t("licences.licenceN")}
                        // label="№"
                        variant="outlined"
                        style={{ width: 200, marginRight: 15 }}
                        value={new Date(
                          protocolDate
                        ).toLocaleString('de-DE', {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",

                        })}
                        inputProps={{
                          style: { textAlign: "center", fontSize: 24 },
                          disabled: true,
                          // textAlign: "center",
                        }}
                      />

                      <Typography variant='h6' style={{}}>
                        року
                      </Typography>
                    </Grid>)
                  }
                </Grid>}

              {note && <Grid container item xs={12} style={{ marginTop: 10 }}>
                <Typography>
                  {note}
                </Typography>
              </Grid>}

              <Grid container item xs={12} style={{ marginTop: 10 }}>
                <Grid container item xs={12}>

                  <div style={{ width: 150 }}>
                    <Typography variant='h6' >
                      Початок:
                    </Typography>
                  </div>

                  <Typography variant='h6'>

                    {new Date(
                      startDate
                    ).toLocaleTimeString([], {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour12: false,
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <div style={{ width: 150 }}>
                    <Typography variant='h6' >
                      Кінець:
                    </Typography>
                  </div>
                  <Typography variant='h6'>
                    {new Date(
                      endDate
                    ).toLocaleTimeString([], {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour12: false,
                      hour: "2-digit",
                      minute: "2-digit",
                    })}

                  </Typography>
                </Grid>

                {(isAdmin || isAssist) && stats && stats.quorum && stats.registeredNotaries && (
                  <>
                    <Grid container item xs={12}>
                      <div style={{ width: 150, marginTop: 15 }}>
                        <Typography variant='body1' >
                          Зареєстровано:
                        </Typography>
                      </div>
                      <Typography variant='body1' style={{ marginTop: 15 }}>
                        {`${stats.registeredNotaries} `}
                      </Typography>

                      {!matches980 && stats.registeredNotariesSourceType.id == RegisteredNotariesSourceType.Registry.valueOf() &&
                        <Typography variant='caption' style={{ marginLeft: 8, marginTop: 17 }}>
                          {` ( ${stats.registeredNotariesSourceType.name} ) `}
                        </Typography>
                      }
                      {!matches980 && stats.registeredNotariesSourceType.id == RegisteredNotariesSourceType.Manual.valueOf() &&
                        <Typography variant='caption' style={{ marginLeft: 8, marginTop: 17 }}>
                          {` ( ${stats.registeredNotariesSourceType.name} - ${stats.registeredNotariesSource} ) `}
                        </Typography>
                      }
                    </Grid>
                    <Grid container item xs={12}>
                      <div style={{ width: 150, marginTop: 5 }}>
                        <Typography variant='body1' >
                          Кворум:
                        </Typography>
                      </div>
                      <Typography variant='body1' style={{ marginTop: 5 }}>
                        {`${stats.quorum} `}
                      </Typography>
                      {!matches980 &&
                        <Typography variant='caption' style={{ marginLeft: 8, marginTop: 7 }}>
                          {` ( ${stats.quorumType.name} ) `}
                        </Typography>
                      }
                    </Grid>
                  </>
                )}

              </Grid>


            </Grid>
            {!matches530 && <Grid container item style={{ width: '190px' }}>

              <Grid item style={{ width: '100%', position: 'relative' }}>

                {/* <Grid container item xs={ 12 } > */}
                <Chip
                  style={{ position: 'absolute', right: '0px', backgroundColor: colorHandler(status.id) }}
                  color='primary'
                  // icon={<AssignmentIndIcon />}
                  label={<span style={{ fontSize: 18 }}>{status.name}</span>}
                />
                {/* </Grid> */}

              </Grid>

            </Grid>}
          </Grid>

        </Card>



        <Grid container item xs={12} direction='row' justify='space-between'>

          <Grid item>

            {createdAt && createdBy && (
              <Typography align="right" variant="caption">
                {`${createdBy.licence.fio} | ${new Date(
                  createdAt
                ).toLocaleTimeString([], {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour12: false,
                  hour: "2-digit",
                  minute: "2-digit",
                })}`}
              </Typography>
            )}
          </Grid>
          <Grid item>

            {id && (
              <Typography align="right" variant="caption">
                {id}
              </Typography>
            )}
          </Grid>

        </Grid>
      </>
    </Grow>
  );
};

export default withSnackbar(BulletinVoting);
