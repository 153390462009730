import React from "react";
// import { Link, Redirect } from "react-router-dom";
import queryString from "query-string";
// import { AUTH_PATH, LOGIN_PATH } from "../Routes/var_PATHS";
import Login from "../Login/Login";
import { Grid, Typography, useTheme } from "@material-ui/core";

const ErrorPage: React.FC = (props: any) => {
  const queryParams = queryString.parse(props.location.search);
  const theme = useTheme();

  return (
    <Grid container item xs={12} justify="center">
      <Grid container item xs={12} justify="center" style={{ padding: 30 }}>
        <Typography
          variant='h6'
          align="center"
          style={ {
            color: theme.palette.error.main,
            backgroundColor: theme.palette.grey[900],
            whiteSpace: 'pre-wrap'
          } }
        >
          {queryParams.message}
        </Typography>
      </Grid>
      <Grid container item xs={12} justify="center">
        <Login />
      </Grid>

      {/* <Redirect to={LOGIN_PATH} />  */}
    </Grid>
  );
};

export default ErrorPage;
