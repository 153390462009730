/* eslint-disable valid-typeof */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import {
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
  useTheme,
  IconButton,
  Tooltip,
  withStyles,
  InputBase,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CircularSpinner from "../spinners/circular";

import { Role } from "../../models/static.model";
import { dataService } from "../../services/dataService";
import history from "../../utils/history";
import { RegisteredNotariesSourceType, VotingOption, VotingType } from "../../models/voting.model";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    position: "relative",
    left: -10,
    padding: "5px",
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    paddingTop: 110
  },
  infScroll: {
    width: "100%",
    // position: "absolute",
    // height: "max-content !important",
    overflow: "visible !important"
  },
  user: {
    border: `2px solid ${theme.palette.background.paper}`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    padding: 10
  },
  admin: {},

  votingItem: {
    cursor: "pointer",
    minHeight: 48,
    width: "100%",
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper,

    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 2px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 0px 10px ${theme.palette.primary.main}`
    }
  },

  selectedItem: {
    cursor: "pointer",
    minHeight: 48,
    width: "100%",
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 8px ${theme.palette.primary.main}`,
    transition: "0.2s ease-in-out"
  },

  disabledItem: {
    minHeight: 48,
    width: "100%",
    border: `2px solid ${theme.palette.grey[600]}`,
    backgroundColor: theme.palette.grey[600],
    borderRadius: 25,
    boxSizing: "border-box",
    boxShadow: `0px 0px 1px ${theme.palette.grey[600]}`,
    transition: "0.2s ease-in-out"
  }
}));


const GreenTextTypography = withStyles({
  root: {
    color: "#008000"
  }
})(Typography);



const ResultSection = (props: any) => {

  const matches980 = useMediaQuery("(max-width:980px)");

  const classes = useStyles();

  const {
    isDecisionDone,
    scoredOptions,
    votedUsers,
    //Stats 
    quorumType,
    quorum,
    registeredNotariesSourceType,
    registeredNotaries,
    registeredNotariesSource
  } = props.result


  const {
    subject,
    body,
    note,
    hashId,
    votingType
  } = props.voting;

  const myRole = useMemo(() => dataService.getRoleSync(), []);

  const isAdmin = myRole === Role.Admin;

  const bulletinHashId: string = String(history.location.pathname.split("/").pop());

  const isBulletinRoutePath: boolean = String(history.location.pathname.split("/").slice(-2).shift()) === "bulletins";

  const isArchiveRoutePath: boolean = String(history.location.pathname.split("/").slice(-2).shift()) === "archive";

  const goToVotingResultReport = (bulletinHashId: string, votingHashId: string) => {
    isBulletinRoutePath && history.push("/bulletins/" + bulletinHashId + "/voting/" + votingHashId);
    isArchiveRoutePath && history.push("/archive/" + bulletinHashId + "/voting/" + votingHashId);
  }

  const goToVotingAttendanceReport = (bulletinHashId: string, votingHashId: string) => {
    isBulletinRoutePath && history.push("/bulletins/" + bulletinHashId + "/voting/" + votingHashId + "/attendance");
    isArchiveRoutePath && history.push("/archive/" + bulletinHashId + "/voting/" + votingHashId + "/attendance");
  }

  const goToVotingAuthlogReport = (bulletinHashId: string, votingHashId: string) => {
    isBulletinRoutePath && history.push("/bulletins/" + bulletinHashId + "/voting/" + votingHashId + "/authlog");
    isArchiveRoutePath && history.push("/archive/" + bulletinHashId + "/voting/" + votingHashId + "/authlog");
  }

  const sortedScoredOptions: Array<VotingOption> = scoredOptions.sort((a: VotingOption, b: VotingOption) => a.position!! - b.position!!)

  return (
    <>
      {props.hashId ? (
        <Grid
          container
          item
          xs={12}
          style={{ margin: '20px 0' }}
        >
          {votingType && (
            <Grid container item xs={12}>
              <Typography variant="h6">
                {` ${props.votingNumber}) ${votingType.name}:`}
              </Typography>
            </Grid>
          )}

          {props.votingNumber && (
            <Grid container item xs={12}>
              <Typography variant="h6">
                {` ${subject && subject}`}
              </Typography>
            </Grid>
          )}

          {body && (
            <Grid container item xs={12} style={{ margin: "10px 0" }}>
              <InputBase fullWidth multiline type='text' readOnly value={body} inputProps={{ 'aria-label': 'naked' }} />
            </Grid>
          )}

          {note && (
            <Grid container item xs={12}>
              <Typography>{note}</Typography>
            </Grid>
          )}


          {quorum && registeredNotaries && (
            <>
              <Grid container item xs={12}>
                <div style={{ width: 150, marginTop: 15 }}>
                  <Typography variant='body1' >
                    Зареєстровано:
                  </Typography>
                </div>
                <Typography variant='body1' style={{ marginTop: 15 }}>
                  {`${registeredNotaries} `}
                </Typography>

                {!matches980 && registeredNotariesSourceType.id === RegisteredNotariesSourceType.Registry.valueOf() &&
                  <Typography variant='caption' style={{ marginLeft: 8, marginTop: 17 }}>
                    {` ( ${registeredNotariesSourceType.name} ) `}
                  </Typography>
                }
                {!matches980 && registeredNotariesSourceType.id === RegisteredNotariesSourceType.Manual.valueOf() &&
                  <Typography variant='caption' style={{ marginLeft: 8, marginTop: 17 }}>
                    {` ( ${registeredNotariesSourceType.name} - ${registeredNotariesSource} ) `}
                  </Typography>
                }
              </Grid>
              <Grid container item xs={12}>
                <div style={{ width: 150, marginTop: 0 }}>
                  <Typography variant='body1' >
                    Кворум:
                  </Typography>
                </div>
                {isDecisionDone &&
                  <Typography variant='body1' style={{ marginTop: 0, color: 'green' }}>
                    {`${quorum} `}
                  </Typography>
                }
                {!isDecisionDone &&
                  <Typography variant='body1' style={{ marginTop: 0, color: 'red' }}>
                    {`${quorum} `}
                  </Typography>
                }
                {!matches980 &&
                  <Typography variant='caption' style={{ marginLeft: 8, marginTop: 3 }}>
                    {` ( ${quorumType.name} ) `}
                  </Typography>
                }
              </Grid>
            </>
          )}


          {votedUsers &&
            <Grid container item xs={12} >
              <div style={{ width: 150, marginBottom: 10 }}>
                <Typography variant='body1' >
                  Проголосували:
                </Typography>
              </div>
              {isDecisionDone &&
                <Typography variant='body1' style={{ marginBottom: 0, color: 'green' }}>
                  {votedUsers}
                </Typography>
              }
              {!isDecisionDone &&
                <Typography variant='body1' style={{ marginBottom: 0, color: 'red' }}>
                  {votedUsers}
                </Typography>
              }
            </Grid>
          }

          <Grid container item xs={12} justify="center" style={{ margin: "10px 0" }}>
            <Table size="small" aria-label="result-report">
              <TableHead>
                <TableRow>
                  <TableCell>{"Місце"}</TableCell>
                  <TableCell>{"Варіант"}</TableCell>
                  <TableCell>{"Проголосували"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedScoredOptions.map((option: VotingOption) => (
                  <TableRow key={option.hashId} >
                    <TableCell>
                      {option.isWinner &&
                        <GreenTextTypography variant="h6">
                          {option.position}
                        </GreenTextTypography>
                      }
                      {!option.isWinner &&
                        <Typography variant="body2">
                          {option.position}
                        </Typography>
                      }
                    </TableCell>
                    <TableCell>
                      {option.isWinner &&
                        <GreenTextTypography variant="h6">
                          {`${option.name}`}
                        </GreenTextTypography>
                      }
                      {!option.isWinner &&
                        <Typography variant="body2">
                          {`${option.name}`}
                        </Typography>
                      }
                    </TableCell>
                    <TableCell>
                      {option.isWinner &&
                        <GreenTextTypography variant="h6">
                          {`${option.optionScore} ( ${option.percentage} % )`}
                        </GreenTextTypography>
                      }
                      {!option.isWinner &&
                        <Typography variant="body2">
                          {`${option.optionScore} ( ${option.percentage} % )`}
                        </Typography>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>

          <Grid container item>
            <Link
              component="button"
              variant="body1"
              onClick={() => { goToVotingResultReport(bulletinHashId, hashId) }}>
              {"Переглянути Реєстр Голосів"} &rarr;
            </Link>
          </Grid>

          {isAdmin &&
            <Grid container item>
              <Link
                style={{ marginTop: 10 }}
                component="button"
                variant="body1"
                onClick={() => { goToVotingAttendanceReport(bulletinHashId, hashId) }}>
                {`Переглянути Явку ${votingType.name}`} &rarr;
              </Link>
            </Grid>
          }

          {isAdmin &&
            <Grid container item>
              <Link
                style={{ marginTop: 10 }}
                component="button"
                variant="body1"
                onClick={() => { goToVotingAuthlogReport(bulletinHashId, hashId) }}>
                {`Переглянути Журнал Аутентифікації за період ${votingType.name}`} &rarr;
              </Link>
            </Grid>
          }

        </Grid>
      ) : (
        <Grid container justify="center" className={classes.spinner}>
          <CircularSpinner />
        </Grid>
      )
      }
    </>
  );
};

export default ResultSection;
